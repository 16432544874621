import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  DialogContent,
  FormGroup,
  FormControl,
  FormControlLabel,
  TextField,
  DialogActions,
  Button,
  Dialog,
} from "@material-ui/core";
import CloseBar from "../../components//dialogCloseBar";

const propTypes = Object.freeze({
  isOpen: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  url: PropTypes.string,
  token: PropTypes.string,
  applicationId: PropTypes.string,
  defaultUrl: PropTypes.string,
  defaultToken: PropTypes.string,
  defaultApplicationId: PropTypes.string,
});

export default function ComposerConfigDialog({
  isOpen,
  onAccept,
  onReject,
  onReset,
  url = "",
  token = "",
  applicationId = "",
  defaultUrl = "",
  defaultToken = "",
  defaultApplicationId = "",
}) {
  const { t } = useTranslation();

  const [isReseted, setReseted] = useState(false);
  const [data, setData] = useState({
    url,
    token,
    applicationId
  });

  useEffect(() => setReseted(false), [isOpen]);
  useEffect(() => {
    setData({
      url: defaultUrl,
      token: defaultToken,
      applicationId: defaultApplicationId
    });
  }, [isOpen, url, token, applicationId]);

  return (
    <Dialog
      fullScreen={true}
      maxWidth="sm"
      fullWidth={true}
      scroll="paper"
      open={isOpen}
      onClose={onReject}
    >
      <CloseBar onClose={onReject} title={t("datasets.load_config")}/>
      <DialogContent dividers={true}>
        <FormGroup>
          <FormControl style={{ margin: "15px 0" }}>
            <FormControlLabel
              label={
                <div style={{ textAlign: "left", marginRight: "20px" }}>
                  {t("datasets.dialog_composer_url")}
                </div>
              }
              labelPlacement="start"
              control={
                <TextField
                  size="small"
                  placeholder={defaultUrl}
                  value={data.url}
                  style={{ width: "80%", marginRight: "10px" }}
                  onChange={(event) =>
                    setData({ ...data, url: event.target.value })
                  }
                  variant="outlined"
                  fullWidth
                />
              }
            />
          </FormControl>
          <FormControl style={{ margin: "15px 0" }}>
            <FormControlLabel
              label={
                <div style={{ textAlign: "left", marginRight: "20px" }}>
                  {t("projects.composer_app_id")}
                </div>
              }
              labelPlacement="start"
              control={
                <TextField
                  size="small"
                  placeholder={defaultApplicationId}
                  value={data.applicationId}
                  style={{ width: "80%", marginRight: "10px" }}
                  onChange={(event) =>
                    setData({ ...data, applicationId: event.target.value })
                  }
                  variant="outlined"
                  fullWidth
                />
              }
            />
          </FormControl>
          <FormControl style={{ margin: "15px 0" }}>
            <FormControlLabel
              label={
                <div style={{ textAlign: "left", marginRight: "20px" }}>
                  {t("datasets.dialog_composer_token")}
                </div>
              }
              labelPlacement="start"
              control={
                <TextField
                  multiline={true}
                  rows={5}
                  rowsMax={5}
                  size="small"
                  placeholder={defaultToken}
                  value={data.token}
                  style={{ width: "80%", marginRight: "10px" }}
                  onChange={(event) =>
                    setData({ ...data, token: event.target.value })
                  }
                  variant="outlined"
                  fullWidth
                />
              }
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setReseted(true);
            setData({ url: "", composer_app_id: "", token: "" });
            onReset(undefined);
          }}
          color="primary"
        >
          {t("common.reset")}
        </Button>
        <div style={{ marginLeft: "auto" }}>
          <Button onClick={onReject} color="primary">
            {t("common.cancel")}
          </Button>
          <Button
            onClick={() => onAccept(data, isReseted)}
            color="primary"
            variant="contained"
            disabled={!isReseted && !(data.url && data.token)}
          >
            {t("common.save")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

ComposerConfigDialog.propTypes = propTypes;
