import React, { Component } from 'react';
import { connect } from "react-redux";

import { REFRESH_TOKEN_INTERVAL } from './core/constants';
import { setUrl, setLang, refreshToken, setOauthServer } from './features/settings';
import { getDataNew as getData } from "./core/fetchService";
import './css/App.css';
import Dashboard from './view/pages/dashboard';


class App extends Component {
	constructor(props) {
		super(props);

		const { lang } = window['runConfig'];
		this.props.setUrl('');
		this.props.setLang(localStorage.getItem('i18nextLng') || lang);
	}

	interval;

	componentDidMount() {
    getData(`/oauth_server`, this.props.dispatch, data => {
      this.props.setOauthServer(data.oauth_server || false);
      if (!data.oauth_server)
        this.interval = setInterval(() => this.props.refreshToken(), REFRESH_TOKEN_INTERVAL * 1000);
		});
	}

	componentWillUnmount() {
    if (!this.props.oauthServer)
      clearInterval(this.interval);
	}

	render() {
		return (
			<div className="App">
        <Dashboard/>
			</div>
		);
	}
}

const mapStateToProps = state => ({
  oauthServer: state.settings.oauthServer,
});

const mapDispatchToProps = dispatch => {
	return {
    dispatch, 
		setUrl:         obj =>  { dispatch(setUrl(obj)); },
		setLang:        obj =>  { dispatch(setLang(obj)); },
		refreshToken:   () =>   { dispatch(refreshToken()); },
		setOauthServer: s =>    { dispatch(setOauthServer(s)); },
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
