import { createSlice } from "@reduxjs/toolkit";
import { LANG } from "../core/constants";
import { getDataNew as getData, postDataNew, putDataNew as putData, fetchAll } from "../core/fetchService";
import { SOME_SETTINGS } from "../core/constants";
import { clearFilters } from "./filters.js";
import i18next from "i18next";

const setState = name => (state, action) => {
  state[name] = action.payload;
};

const NO_PROJECTS = {
  projectName: "Not Selected",
  projectId: null,
  /* BE project fields, see setProjectInfo below */
  enableDefaultBatches: undefined,
  composerConfig: undefined,
  similarityConfig: undefined,
};

export const settings = createSlice({
  name: "settings",
  initialState: {
    lang: LANG.EN,
    userName: "Unknown user",
    url: null,
    isLoading: false,
    token: null,
    oauthServer: null,
    /*
    users: null,
    userGroups: null,
    */
    projectInfo: NO_PROJECTS,
    projectDatasets: null,
    projects: null,
    selectedDataSetId: null,
    locationPath: "",
    assotiatedEntitiesList: null,
    selectedModelId: null,
    projectModel: null,
    lookupList: null,
    intents: null,
    modelConfigList: [],
    testModelList: null,
    autoModelList: null,
  },
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload;
      i18next.changeLanguage(state.lang);
    },
    setUserName:                                setState('userName'),
    setUrl:                                     setState('url'),
    setToken:                                   setState('token'),
    setRefreshToken:                            setState('setRefreshToken'),
    setOauthServer:                             setState('oauthServer'),
    setIsLoading:                               setState('isLoading'),
    /*
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setUserGroups: (state, action) => {
      state.userGroups = action.payload;
    },
    */
    setProjectInfo: (state, action) => {
      if (!action.payload) {
        state.projectInfo = NO_PROJECTS;
        return;
      }
      state.projectInfo = { ...(state.projects.find(p => p._id === action.payload.id) || {}) };
      state.projectInfo.projectName = action.payload.name;
      state.projectInfo.projectId = action.payload.id;

      if (action.payload.id && state.projects)
        state.projectDatasets = state.projects.find(p => p._id === action.payload.id)?.datasets;
    },
    setProjects: (state, action) => {
      state.projects = action.payload;
      if (state.projectInfo.projectId) {
        state.projectDatasets = action.payload.find(p => p._id === state.projectInfo.projectId)?.datasets;
      } else if (state.projects) {
        state.projectInfo = { ...(state.projects[0] || {}) };
        state.projectInfo.projectName = state.projects[0]?.name;
        state.projectInfo.projectId = state.projects[0]?._id;
      }
    },
    setProjectDataSet: (state, action) => {
      state.projectDatasets = action.payload;
      state.projects = state.projects.map(p => p.id == state.projectInfo.projectId
                                          ? ({ ...p, datasets: action.payload }) : p);
    },
    setSelectedDataSetId: (state) => {
      let currentDataSetId = localStorage.getItem("selectedDataSetId");

      let dataSetId = currentDataSetId && currentDataSetId !== 'null' ?
        currentDataSetId :
        Array.isArray(state.projectDatasets) ? state.projectDatasets[0]?._id : null;

      dataSetId? localStorage.setItem("selectedDataSetId", dataSetId): localStorage.removeItem("selectedDataSetId");
      state.selectedDataSetId = dataSetId;
    },
    setLocationPath:                            setState('locationPath'),
    setAssotiatedEntitiesList:                  setState('assotiatedEntitiesList'),
    setLookupList:                              setState('lookupList'),

    updateDataset: (state, action) => {
      const dataset = action.payload;
      state.projectDatasets = state.projectDatasets.map(ds => ds._id == dataset._id ? dataset : ds);
    },
    updateLookupList: (state, action) => {
      const { lookupId, value, entityId } = action.payload;
      state.lookupList = (state.lookupList || []).map(l => {
        if (l._id !== lookupId) return l;
        if (!entityId)
          return { ...l, values: [...l.values, value] };
        else
          return {
            ...l, values: l.values.map(v => {
              if (v._id !== entityId) return v;
              return value;
            }),
          };
      });
    },
    addToLookupDict: (state, action) => {
      const { lookupId, value } = action.payload;

      if (!state.lookupList[lookupId].values)
        state.lookupList[lookupId].values = [value];
      else
        state.lookupList[lookupId].values.push(value);
    },
    updateLookupDict: (state, action) => {
      const { lookupId, values, value } = action.payload;

      if (!state.lookupList[lookupId].values)
        state.lookupList[lookupId].values = values;
      else if (value) {
        state.lookupList[lookupId].values = state.lookupList[lookupId].values.map(l => {
          if (l._id === value._id) return value;
          return l;
        });
      }
    },
    setSlots:                                   setState('slots'),
    setModelConfigList:                         setState('modelConfigList'),
    setTestModelList:                           setState('testModelList'),
    setAutoModelList:                           setState('autoModelList'),
  },
});

export const {
  setLang, setToken, setRefreshToken, setUrl, setUserName, /* setUsers, setUserGroups, */
  setProjectInfo, setIsLoading, setOauthServer, setProjects, setProjectDataSet,
  setSelectedDataSetId, setLocationPath,
  setAssotiatedEntitiesList, setLookupList, updateDataset, updateLookupList, updateLookupDict, addToLookupDict,
  setSlots, setModelConfigList, setTestModelList, setAutoModelList
} = settings.actions;

export const getLang =                    state => state.settings.lang;
export const getToken =                   state => state.settings.token;
export const getRefreshToken =            state => state.settings.setRefreshToken;
export const getUserName =                state => state.settings.userName;
export const getUrl =                     state => state.settings.url;
export const getIsLoading =               state => state.settings.isLoading;
export const getOauthServer =             state => state.settings.oauthServer;
/*
export const getUsers =                   state => state.settings.users;
export const getUserGroups =              state => state.settings.userGroups;
*/
export const getProjectInfo =             state => state.settings.projectInfo;
export const getProjectName =             state => state.settings.projectInfo.projectName;
export const getProjectId =               state => state.settings.projectInfo.projectId;
export const getProjectDatasets =          state => state.settings.projectDatasets;
export const getSelectedDataSetId =       state => state.settings.selectedDataSetId;
export const getLocationPath =            state => state.settings.locationPath;
export const getAssotiatedEntitiesList =  state => state.settings.assotiatedEntitiesList;
export const getLookupList =              state => state.settings.lookupList;
export const getProjects =                state => state.settings.projects;

/*
export const fetchUsers = () => dispatch =>
  getData(`/api/user`, dispatch, data => dispatch(setUsers(data.users)));

export const fetchUserGroups = () => dispatch =>
  getData(`/api/user_groups`, dispatch, data => dispatch(setUserGroups(data.user_groups)));
*/

export const fetchEntityListByProjectId = obj => async dispatch => {
  const { projectId } = obj;
  return getData(`/api/lookup?project=${projectId}`, dispatch, data => {
    dispatch(setLookupList(data.lookups));
    dispatch(setAssotiatedEntitiesList(
      data.lookups.map(lookup => {
        return ({
          value: lookup._id,
          label: `${lookup.scope}.${lookup.name}`,
          scope: lookup.scope,
          name: lookup.name,
        });
      })));
  });
};

export const fetchDataSets = obj => dispatch => {
  const { projectId, datasets, adjust = _ => _, stat = true } = obj;
  return projectId
    ? getData(`/api/project/${projectId}`, dispatch, data => {
      dispatch(setProjectDataSet(data.project.datasets));
      if (stat)
        dispatch(fetchDataSetSizes(data.project.datasets));
    })
    : Promise.all(datasets.map(ds => getData(`/api/dataset/${ds._id}`, dispatch, data => {
      dispatch(updateDataset(adjust(data.dataset)));
    })));
}

export const fetchDataSetSizes = datasets => dispatch => {
  const BATCH_SIZE = datasets.length; // seems no need in batching now // 10;
  for (let i=0; i<datasets.length; i+=BATCH_SIZE) {
    postDataNew('/api/dataset/stats', {
      datasets: datasets.slice(i,i+BATCH_SIZE).map(d => d._id),
    }, dispatch, stats => {
      dispatch(setProjectDataSet(datasets.map((ds,j) => {
        return j<i || j>=i+BATCH_SIZE ? ds : { ...ds, stat: stats[ds._id] };
      })));
    });
  }
}

export const fetchProjects = () => dispatch => 
  getData(`/api/project`, dispatch, data => dispatch(setProjects(data.projects)));

export const fetchAddToLookup = (obj, callback) => dispatch =>
  postDataNew(obj.url, obj.data, dispatch, data => callback && callback(data));

export const fetchUpdateInLookup = (obj, callback) => dispatch =>
  putData(obj.url, obj.data, dispatch, data => callback && callback(data));

export const fetchEntityValuesByLookupId = obj => dispatch => {
  const { lookupId } = obj;
  return getData(`/api/lookup/${lookupId}`, dispatch, data => {
    return dispatch(updateLookupDict({ lookupId, values: data.lookup?.values || [] }));
  });
};

// Note: due to two async actions (dispatch) inside the callback fetchAllSlots can't be waited
//    for full completion; if this ever needed, the callback should be modified
export const fetchAllSlots = obj => dispatch =>
  getData(`/api/slot?project=${obj.projectId}`, dispatch, data => {
    const entityIds = (s) => {
      let names = [];
      //push
      (s?.entityIds || []).forEach(id => {
        names = [...names, data.lookups[id]?.name || id];
      });

      return names.join(", ");
    };

    dispatch(setSlots(
      data.slots.map(s => ({
        _id: s._id,
        name: s.name,
        entityIds: entityIds(s),
      })),
    ));

    dispatch(setAssotiatedEntitiesList(
      Object.keys(data.lookups).map(_id => {
        return ({
          value: _id,
          label: `${data.lookups[_id].scope}.${data.lookups[_id].name}`,
          scope: data.lookups[_id].scope,
          name: data.lookups[_id].name,
        });
      }),
    ));
  });

export const fetchModelConfigList = obj => dispatch =>
  getData(`/api/model_config?project=${obj.projectId}`, dispatch, data =>
    dispatch(setModelConfigList(data.model_configs)));

export const fetchTestModelList = obj => dispatch =>
  getData(`/api/testmodel?project=${obj.projectId}`, dispatch, data => {
    data.testmodels.forEach(tm => { tm.labels = data.labels[tm._id] });
    dispatch(setTestModelList(data));
  });

export const fetchAutoModelList = obj => dispatch =>
  getData(`/api/automodel?project=${obj.projectId}`, dispatch, data =>
    dispatch(setAutoModelList(data)));

export const refreshToken = () => dispatch => {
  const storage = JSON.parse(localStorage.getItem(SOME_SETTINGS.TOKEN));
  if (storage)
    postDataNew(
        `/token`,
        { refreshToken: storage.refreshToken },
        dispatch,
        data => {
      const token = data.token;
      if (token)
        localStorage.setItem(SOME_SETTINGS.TOKEN, JSON.stringify({ ...storage, token }));
      else
        localStorage.clear();
    });
};

export const dispatchChainingComboProjectDataset = obj => async dispatch => {
  const { projectId, name } = obj;

  return await fetchAll([
    dispatch(setProjectInfo({ id: projectId, name: name })),
    dispatch(fetchDataSets({ projectId: projectId })),
    dispatch(setSelectedDataSetId()),
  ]).then(res => {
    return res[res.length - 1];
  });
};

export const changeSelectedDataSetId = (dispatch, id) => {
  localStorage.setItem("selectedDataSetId", id);
  dispatch(setSelectedDataSetId());
  // dispatch(clearFilters({ only: ['annotation', 'sim']})); // TODO: unite w/dataset change
};

export default settings.reducer;
