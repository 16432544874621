import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import MaterialUIIconButton from "@material-ui/core/IconButton";

export default function IconButton(props) {
  const {
    /* extra props, required */
    title,
    onClick,
    Icon,

    /* extra props, optional */
    fontSize,

    /* defaults for mui IconButton's props */
    size = "small",

    ...other_props
  } = props;

  const lprops = {
    ...{ size },
    ...other_props,
  };

  const icon_props = {
    ...(fontSize ? { fontSize } : {})
  };

  const _Icon = () => (
    <MaterialUIIconButton onClick={onClick} { ...lprops }>
      <Icon {...icon_props}/>
    </MaterialUIIconButton>
  );

  return title ? (
    <Tooltip title={title}>
      <span>{/* span is required by Tooltip in case MaterialUIIconButton gets 'disabled' prop */}
        {_Icon()}
      </span>
    </Tooltip>
  ) : _Icon();
}
