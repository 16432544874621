import {
  ClearToken, GetToken,
  decodeMessage, showSuccess, showError,
}                                                       from '../core/utils';
import { setIsLoading }                                 from "../features/settings";
import history                                          from '../other/history';

async function request(url, method, options = {}) {
  const token = GetToken();
  const opt_headers = options.headers || {};

  delete options.headers;

  const auto_content_type = opt_headers['Content-Type'] === null;

  if (auto_content_type)
    delete opt_headers['Content-Type'];

	// Default options are marked with *
	return await fetch(url, {
		method, // *GET, POST, PUT, DELETE, etc.
		mode: 'cors', // no-cors, *cors, same-origin
		cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		credentials: 'same-origin', // include, *same-origin, omit
		headers: {
      ...(auto_content_type ? {} : { 'Content-Type': 'application/json' }),
			'authorization': 'bearer ' + token,
      ...opt_headers,
		},
		redirect: 'follow', // manual, *follow, error
		referrerPolicy: 'no-referrer', // no-referrer, *client
    ...options
	});
}

function requestWrap(methodFunc, { url, data, dispatch, on_success, options = {} }) {
  const show_loading = !options.disable_show_loading;
  show_loading && dispatch(setIsLoading(true));
  const args = [postData, putData, uploadFile].includes(methodFunc) ? [data] : [];
  let kind = 'fetch';
  return methodFunc(url, ...args)
    .then(data => {
      if (data.error)
        throw data.error;
      kind = 'on_success';
      const res = on_success && on_success(data);
      return res !== undefined ? res : data; // for 'await' statements used with requestWrap and its callers
    })
    .catch(error => {
      if (error.name != "AbortError") {
        console.error(`[${methodFunc.name}::${kind}]:`, error);
        const error_prefix = options.error_prefix ? options.error_prefix +': ' : '';
        const p = options.message_prefix || 'NO_MESSAGE_PREFIX';
        const m = error.message || '';
        const t = options.t;
        showError(dispatch, t)(error_prefix + (t? decodeMessage(m, t, p) : m));
      }
      return { error }; // for 'await's
    })
    .finally(() => {
      show_loading && dispatch(setIsLoading(false));
    });
}

const _getData = async (url, is_json) => {
	const response = await request(url, 'GET');
	return {
    data: await checkStatus(response, is_json),
    response,
  };
};

export const getData = async url => (await _getData(url, true)).data;
export const getRawData = url => _getData(url);

export async function fetchData(url, data = {}, method = 'POST') {
	const response = await request(url, method, {
		body: JSON.stringify(data) // body data type must match "Content-Type" header
	});
	return await checkStatus(response, true);
}

export const postData = async (url, data) => await fetchData(url, data, 'POST');
export const putData = async (url, data) => await fetchData(url, data, 'PUT');

export async function uploadFile(url, formData) {
	const response = await request(url, 'POST', {
		headers: {
			// 'Content-Type': 'multipart/form-data', <--- does not work w/o setting mfd boundary
			// 'Content-Type': 'application/x-www-form-urlencoded',
			'Content-Type': null,
		},
		body: formData // body data type must match "Content-Type" header
	});
	return await checkStatus(response, true);
}

export async function deleteData(url) {
	const response = await request(url, 'DELETE');
	return await checkStatus(response, true);
}

function checkStatus(response, is_json) {
  if (response.ok && response.status >= 200 && response.status < 300 || response.status == 500)
    return is_json ? response.json() : response.blob();
//  if (response.status === 422)
//    return response.json();
  if (response.status === 401) {
    ClearToken();
    history.push('/sign-in');
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export const getRawDataNew = (url, dispatch, on_success, options) =>
  requestWrap(getRawData, { url, dispatch, on_success, options });

export const getDataNew = (url, dispatch, on_success, options) =>
  requestWrap(getData, { url, dispatch, on_success, options });

export const postDataNew = (url, data, dispatch, on_success, options) =>
  requestWrap(postData, { url, data, dispatch, on_success, options });

export const putDataNew = (url, data, dispatch, on_success, options) =>
  requestWrap(putData, { url, data, dispatch, on_success, options });

export const uploadFileNew = (url, data, dispatch, on_success, options) => {
  const f = on_success && (data => {
    data.message && showSuccess(dispatch, options.t)(data.message);
    on_success(data);
  });
  return requestWrap(uploadFile, { url, data, dispatch, on_success: f, options });
}

export const deleteDataNew = (url, dispatch, on_success, options) =>
  requestWrap(deleteData, { url, dispatch, on_success, options });

/** FIXME: In case of multiple failures, when actions are based on requestWrap,
 *    there will be a lot of error pop-up flushing. Need to suppress this annoying behaviour,
 *    gather all errors from actions and keep/show then in a conveient way
 */
export const fetchAll = actions => Promise.all(actions);
