import React, { Fragment } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
  getProjectId,
  setProjectInfo,
  setIsLoading,
  fetchProjects,
  setSelectedDataSetId,
} from "../../../features/settings";
import { setMessageState } from '../../../features/messageInfo';
import { postDataNew, deleteData } from '../../../core/fetchService'
import { clearFilters } from "../../../features/filters";
import { MESSAGE_STATUS, DIALOG_USER_STATE } from '../../../core/constants';
import EnhancedTable from '../../components/projectTable';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import EditIcon from '@material-ui/icons/Edit';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../components/confirmDialog'
import Radio from '@material-ui/core/Radio';
import { withTranslation } from 'react-i18next';
import { IsAdmin, setDefaultRowsOnPage, getRunConfigOption } from '../../../core/utils';
import { withStyles } from "@material-ui/styles";
import EditProject from "./edit"
import CloseBar from "../../components/dialogCloseBar";
import iconList from '../../components/helpers/iconList';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    padding: "10px",
    textAlign: "left",
  },
  column: {
    flexBasis: "33.33%",
  },
  heading: {
    fontSize: "12",
  },
  gridList: {
    overflow: "hidden",
  },
  summary: {
    marginLeft: "20px",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  tdTopCell: {
    borderTop: "1px solid #000",
  },
  textField: {
    margin: 8
  }
});

function TableCellRadioButton(props) {
  const projectId = useSelector(getProjectId);
  const dispatch = useDispatch();
  const { id, name } = props;
  return (
    <TableCell padding="checkbox">
      <Radio
        checked={id === projectId}
        onChange={() => {
          dispatch(setProjectInfo({ id: id, name: name }));
          dispatch(clearFilters({ but: ['projects'] })); // TODO: unite with setting project
          window.localStorage.setItem('currentProject', id)
          localStorage.removeItem('selectedDataSetId');
          dispatch(setSelectedDataSetId());
          props.history.push(`/projects/${id}${props.history.location.search}`);
        }}
      />
    </TableCell>
  )
}

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: null,
      name: "",
      description: "",
      editingProjectId: null,
      training_port: null,
      runtime_port: null,
      showModal: false,
      modalContent: null,
      openEditDialog: false
    };
  }

  setMessageErrorState = (obj) => {
    this.props.dispatch(setMessageState(obj));
    this.props.dispatch(setIsLoading(false));
  };

  showSnackBar = (obj) => {
    this.props.dispatch(setMessageState(obj));
  };

  getAllProjects = () => {
    const { dispatch } = this.props;
    return dispatch(fetchProjects());
  }

  getFirstProject = () => {
    const { projects, dispatch, history } = this.props;
    if (!projects?.length)
      return dispatch(setProjectInfo(null));

    const id = projects[0].id;
    const name = projects[0].name;

    dispatch(setProjectInfo({ id, name }));

    localStorage.setItem('currentProject', id)
    localStorage.removeItem('selectedDataSetId');
    dispatch(setSelectedDataSetId());

    history.push(`/projects/${id}`);
  }

  componentDidMount() {
    const query = this.props.history.location.search;
    const href = this.props.history.location.pathname;

    let id = href.includes('/projects/')? href.slice(href.indexOf('/projects/') + '/projects/'.length): null;

    const setProject = (id) => {
      const name = this.props.projects.find(p => p._id == id).name;
      const dispatch = this.props.dispatch;

      dispatch(setProjectInfo({ id, name }));

      dispatch(setSelectedDataSetId());

      this.props.history.push(`/projects/${id}${query}`);
    };

    if (this.props.projects.find(p => p._id == id)) {
      setProject(id)
    } else {
      if (id) {
        this.showSnackBar({ snackBarMessages: this.props.t("projects.not_found"), snackBarVariant: MESSAGE_STATUS.ERROR, snackBarState: true })
      } else if (this.props.projects.find(p => p._id == window.localStorage.getItem('currentProject'))) {
        setProject(window.localStorage.getItem('currentProject'))
      } else if (!id && !window.localStorage.getItem('currentProject')) {
        this.getFirstProject();
      };
    }
  };

  componentDidUpdate() {

  }

  componentWillUnmount() {

  }

  handleEditRow = (state, id) => {
    this.setState({ openEditDialog: state, editingProjectId: id })
  };

  handleDeleteRow = (id, name) => {
    this.setState({ modalContent: `${this.props.t('projects.delete_question')}: "${name}"?`, showModal: true, rowId: id });
  };

  handleCloseModal = (modalState) => {
    const { rowId } = this.state;
    this.setState({ showModal: false });
    if (modalState === DIALOG_USER_STATE.AGREE && rowId) {
      this.props.dispatch(setIsLoading(true));
      deleteData(`/api/project/${rowId}`)
        .then((data) => {
          if (data.error) {
            this.setMessageErrorState({ snackBarMessages: data.error.message, snackBarVariant: MESSAGE_STATUS.ERROR, snackBarState: true });
          }
          else {
            this.getAllProjects()
              .then(this.getFirstProject)
          }
        })
        .catch(error => {
          this.setMessageErrorState({ snackBarMessages: error.message, snackBarVariant: MESSAGE_STATUS.ERROR, snackBarState: true });
        });
    }
  };

  render() {
    const { showModal, modalContent } = this.state;
    const { t, projects } = this.props;

    const headCells =
      [
        { _id: 'name', width: "40%", label: t('common.project'), textSearch: true },
        { _id: 'description', width: "40%", label: t('common.description'), align: 'left', textSearch: true },
        // { _id: 'datasetCount', disablePadding: true, label: 'Datasets', align: 'left' },
        // { _id: 'entriesAndAnnotate', disablePadding: true, label: 'Entries / Annotate', align: 'left' }
      ];

    const rows = projects && projects.map(p => (
      { _id: p._id, name: p.name, description: p.description }
    ));

    return (
      <Fragment>
        {showModal && <ConfirmDialog title={t('projects.confirm_title')} open={showModal} content={modalContent} closeModal={(modalState) => this.handleCloseModal(modalState)} />}
        {rows && 
          <>
            <EnhancedTable
              id="projects"
              passedPage={true}
              orderValue={'name'}
              headCells={headCells}
              rows={rows}
              toolBarName={t('menu.projects')}
              newRowTitle={t("projects.add_new_project")}
              handleClickUpdateRow={this.getAllProjects}
              handleClickNewRow={IsAdmin() ? () => this.handleEditRow(true, null) : null}
              rowsOnPage={setDefaultRowsOnPage(rows.length)}
              checkBoxTableCell={(id, name) => (
                <TableCellRadioButton id={id} name={name} history={this.props.history} />
              )}
              customBtns={(name, id) => (
                <div style={{width: "15%", display: "flex"}}>
                  {iconList([
                    ['common.edit',   EditIcon,   () => this.handleEditRow(true, id)],
                    ['common.delete', DeleteIcon, () => this.handleDeleteRow(id, name)],
                  ], { t })}
                </div>
              )}
            />
            <EditProject
              getAllProjects={this.getAllProjects}
              id={this.state.editingProjectId}
              open={this.state.openEditDialog}
              handleCloseEdit={() => this.handleEditRow(false, null)}
            />
          </>
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  projects: state.settings.projects
});

export default connect(mapStateToProps)(withStyles(styles)(withTranslation()(Project)));
