import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { withTranslation, useTranslation } from "react-i18next";

// import { fetchAutoModelList } from "../../../features/settings";
import { setDefaultRowsOnPage, getRunConfigOption } from "../../../core/utils";
import { getDataNew as getData } from "../../../core/fetchService";
import EnhancedTable from "../../components/projectTable";
import ConfirmDialog from "../../components//confirmDialog";

import TextField from "@mui/material/TextField";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

const UserGroups = (props) => {
  const [groups, setGroups] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    getGroups();
  }, [props.projectId]);

  const getGroups = async () => {
    const USERS_TO_SHOW_LIMIT = 1;
    const show = users => {
      if (!users) return '';
      const more = typeof users[0] == 'object' ? ` // and ${users.shift()[0]} more` : ''; // can't inline due to users.shift()!
      return users.join(', ') + more;
    };
    getData(`/api/user_group?show_users=${USERS_TO_SHOW_LIMIT}`,
            dispatch,
            data => setGroups(data.user_groups.map(g => ({...g, users: show(g.users)}))));
  };

  const postGroup = async (obj, clone) => {
  };

  const deleteGroup = async (id) => {
  };

  const updateGroup = async (obj) => {
  };

  const headCells = [
    { _id: "name",        label: t("common.name") },
    { _id: "users",       label: t("user_groups.users") },
    { _id: "oauth_group", label: t("user_groups.oauth_group") },
    { _id: "createdAt",   label: t("user_groups.createdAt"), dateTime: true },
  ];

  const handleClickRow = () => {};

  return (
    <Fragment>
      {groups && <EnhancedTable
        id="groups_table"
        headCells={headCells}
        rows={groups/*.map(g => ({...g, size: 'to do' }))*/}
        toolBarName={t("menu.user_groups")}
        newRowTitle={t("user_groups.new_group")}
        handleClickNewRow={() => handleClickRow() }
        handleClickUpdateRow={getGroups}
        rowsOnPage={setDefaultRowsOnPage(groups.length)}
        checkBoxTableCell={(id, name, index) => (
          <TableCell padding="default">{index + 1}</TableCell>
        )}
        customBtns={(name, id) => {
          const group = groups?.find(b => b._id === id) || { groupSteps: [] };
          return <div style={{width: "25%", display: "flex"}}>
            <IconButton size="small" aria-label="edit" onClick={() => handleClickRow(id)}>
              <Tooltip title={t("common.edit")}>
                <EditIcon/>
              </Tooltip>
            </IconButton>
            <IconButton size="small" aria-label={t("common.clone")} onClick={() => {
            }}>
              <Tooltip title={t("common.clone")}>
                <FileCopyIcon/>
              </Tooltip>
            </IconButton>
            <IconButton size="small" aria-label={t("common.delete")}
              onClick={() => {/* handleDeleteDialogOpen(id, name) */}}>
              <Tooltip title={t("common.delete")}>
                <DeleteIcon/>
              </Tooltip>
            </IconButton>
          </div>;
        }}
      />}
    </Fragment>
  );
};

const mapStateToProps = state => ({
  projectId: state.settings.projectInfo.projectId,
  projects: state.settings.projects,
  projectDatasets: state.settings.projectDatasets,
});

export default withRouter(connect(mapStateToProps)(withTranslation()(UserGroups)));
