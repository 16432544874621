import React, { Fragment, useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getProjectId } from "../../../features/settings";

import Highlighter from "react-highlight-words";
import { MESSAGE_STATUS, BTN } from "../../../core/constants";
import { getRunConfigOption } from "../../../core/utils";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { getDataNew as getData } from "../../../core/fetchService";
import { setMessageState } from "../../../features/messageInfo";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import CloseBar from "../../components/dialogCloseBar";
import notBackdropClicked from "../../components/helpers/notBackdropClicked";

const useStyles = makeStyles((theme) => ({
  fieldset: {
    margin: "10px",
    padding: "20px",
  },
}));

const SelectAutoModel = (props) => (
  <>
    <Typography style={{ margin: "5px 0" }}>{props.t("batches.auto_annotate")}</Typography>
    <Autocomplete
      id="auto_name"
      size="small"
      fullWidth
      required
      ListboxProps={{ style: { maxHeight: 200 } }}
      style={{ margin: 8, paddingRight: 17 }}
      options={props.options}
      onChange={(event, newValue) => {
        props.handleChange(event, "automodel", newValue?._id);
      }}
      getOptionLabel={(option) => option.name || ""}
      value={props.autoModelList.find(item => item._id === props.editingBatch?.automodel) || null}
      getOptionSelected={(option, value) => option._id === value._id || true}
      renderOption={(option, state) => (<Highlighter
        searchWords={[state.inputValue]}
        autoEscape={true}
        textToHighlight={option.name}
      />)}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={props.t("batches.auto_name")}/>
      )}
    />
  </>
);

const SelectNLUModel = (props) => (
  <>
    <Typography style={{ margin: "5px 0" }}>{props.name}</Typography>
    <Autocomplete
      id={props.id}
      size="small"
      fullWidth
      required
      style={{ margin: 8, paddingRight: 17 }}
      options={props.options}
      ListboxProps={{ style: { maxHeight: 200 } }}
      onChange={(event, newValue) => {
        props.handleChange(event, props.fieldname, newValue?._id);
      }}
      getOptionLabel={(option) => option.name || ""}
      value={props.modelsList.find(item => item._id == (props.editingBatch && props.editingBatch[props.fieldname])) || null}
      getOptionSelected={(option, value) => option._id === value._id || true}
      renderOption={(option, state) => (<Highlighter
        searchWords={[state.inputValue]}
        autoEscape={true}
        textToHighlight={option.name}
      />)}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={props.t("batches.model_name")}/>
      )}
    />
  </>
);

const SelectTestModel = props => (
  <>
    <Typography style={{ margin: "5px 0" }}>Test</Typography>
    <Autocomplete
      id="test"
      size="small"
      fullWidth
      required
      ListboxProps={{ style: { maxHeight: 200 } }}
      style={{ margin: 8, paddingRight: 17 }}
      options={props.editingBatch?.nlu_model_2
        ? props.testsList.filter(t => t.model === props.editingBatch?.nlu_model_2)
        : props.testsList}
      onChange={(event, newValue) => {
        props.handleChange(event, "testmodel", newValue?._id || "");
      }}
      getOptionLabel={(option) => option.name || ""}
      value={props.testsList.find(item => item._id === props.editingBatch?.testmodel) || null}
      getOptionSelected={(option, value) => option._id === value._id || true}
      renderOption={(option, state) => (<Highlighter
        searchWords={[state.inputValue]}
        autoEscape={true}
        textToHighlight={option.name}
      />)}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={props.t("batches.test_name")}/>
      )}
    />
  </>
);

const EditDialog = (props) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const { isOpen, onClose, _id, autoModelList, modelsList, testsList } = props;
    const projectId = useSelector(getProjectId);
    const DEFAULT_BATCHES = getRunConfigOption('enableDefaultBatches');

    const batchTypes = [
      { value: "LOAD_DATA",         name: "Load Data" },
      { value: "TRAIN_TEST",        name: "Train-Test" },
      { value: "SPLIT_TRAIN_TEST",  name: "Train-Split-Test" },
      { value: "DEPLOY",            name: "Deploy" },
      { value: "SPLIT_DEPLOY",      name: "Split-based Deploy" },
      { value: "TRAIN_TEST_DEPLOY", name: "Train-Test-Deploy" },
      { value: "2_CLASSIFIERS",     name: "2 classifiers" },
    ]
    .map(v => {v.name = v.value; return v}); // TODO: load list from BE

    const [editingBatch, setEditingBatch] = useState(null);
    const [receivedBatch, setReceivedBatch] = useState(null);

    const { t } = useTranslation();

    const createEditingBatch = () => {
      setEditingBatch(editingBatch || receivedBatch || { project: projectId, type: "TRAIN_TEST"});
    };

    useEffect(() => {
      if (isOpen) {
        if (_id) {
          getBatch();
        } else {
          createEditingBatch();
        }
      }
    }, [isOpen]);

    useEffect(() => {
      if (editingBatch?.type) {
        createEditingBatch();
      }
    }, [editingBatch?.type]);

    useEffect(() => {
      if (receivedBatch) {
        createEditingBatch();
      }
    }, [receivedBatch]);

    const getBatch = () => {
      getData(`/api/batch/${props._id}`, dispatch, data => setReceivedBatch(data.batch));
    };

    const handleChange = (event, key, value) => {
      setEditingBatch({
        ...editingBatch,
        [key ? key : event?.target.id]: typeof value == 'boolean' || value || value === 0 ? value : event?.target.value,
      });
    };

    const doCancel = () => {
      setEditingBatch(null);
      setReceivedBatch(null);
      onClose(BTN.CANCEL);
    };

    const doApply = () => {
      setEditingBatch(null);
      setReceivedBatch(null);
      onClose(BTN.SAVE);
    };

    const type = editingBatch?.type;

    const disabled = () => {
      const isParamMissing = (arr) => !arr.every(params => editingBatch[params]);
      const doesIncludeType = (arr) => arr.includes(type);

      if (isParamMissing(["name"])) return true;

      if (doesIncludeType(["LOAD_DATA"])) 
        return isParamMissing(["dataset"]) || editingBatch.annotate && isParamMissing(["automodel"]);
      if (doesIncludeType(["TRAIN_TEST_DEPLOY", "DEPLOY", "TRAIN_TEST", "SPLIT_DEPLOY"])) 
        return isParamMissing(["nlu_model_2", "testmodel"]);
      if (doesIncludeType(["2_CLASSIFIERS"]))
        return isParamMissing(["nlu_model_1", "nlu_model_2", "automodel", "testmodel"]);
      if (doesIncludeType(["SPLIT_TRAIN_TEST"])) 
        return isParamMissing(["split_percent", "nlu_model_2"]);

      return false;
    };

    return (
      <Fragment>
        {editingBatch && modelsList && props.datasetsList && autoModelList && testsList &&
        <Dialog
          maxWidth="md"
          fullWidth={true}
          open={isOpen}
          onClose={notBackdropClicked(() => {
            setEditingBatch(null);
            setReceivedBatch(null);
            onClose();
          })}
          scroll="paper"
          aria-labelledby="edit-batch-dialog-title"
        >
          <CloseBar onClose={() => {
            setEditingBatch(null);
            setReceivedBatch(null);
            onClose();
          }} title={_id ? t("batches.edit_batch") : t("batches.new_batch")}/>
          <DialogContent dividers={true}>
            <Grid container>
              <FormControl fullWidth component="fieldset">
                <FormGroup style={{ margin: "5px 0" }}>
                  <Grid item style={{ display: "flex" }}>
                    <TextField
                      id="name"
                      required
                      size="small"
                      style={{ margin: 8, paddingRight: DEFAULT_BATCHES ? 17 : 0 }}
                      label={t("batches.name")}
                      onChange={handleChange}
                      variant="outlined"
                      fullWidth
                      value={editingBatch?.name || ''}
                    />
                    {DEFAULT_BATCHES ?
                    <FormControlLabel
                      style={{ margin: 8, height: "auto" }}
                      control={<Checkbox/>}
                      label={t("batches.default")}
                      checked={editingBatch?.default || false}
                      onChange={(event) => {
                          handleChange(event, "default", Boolean(event.target.checked))
                      }}
                    /> : null}
                  </Grid>
                </FormGroup>
              </FormControl>
            </Grid>
            <TextField
              id="description"
              size="small"
              multiline
              rows={3}
              style={{ margin: 8, paddingRight: 17 }}
              label={t("common.description")}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              value={editingBatch?.description}
            />
            <Autocomplete
              id="type"
              size="small"
              fullWidth
              required
              style={{ margin: 8, paddingRight: 17 }}
              options={batchTypes}
              disableClearable
           // filterSelectedOptions -- if input is editable, it's possible to erase current value and lose it from selection
              ListboxProps={{ style: { maxHeight: 200 } }}
              onChange={(event, newValue) => {
                handleChange(event, "type", newValue?.value);
              }}
              getOptionLabel={(option) => option.name || ""}
              value={batchTypes.find(item => item.value === type)}
              getOptionSelected={(option, value) => option.value === value.value}
           // renderOption={(option, state) => (<Highlighter
           //   searchWords={['^'+ state.inputValue +'$']}
           //   textToHighlight={option.name}
           // />)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label={t("batches.type")}/>
              )}
            />
            <fieldset className={classes.fieldset}>
              <legend>{t(`batches.${type}`)}</legend>
              {type == "LOAD_DATA" ?
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <Typography style={{ margin: "5px 0" }}>{t("batches.import_data_from_dc")}</Typography>
                    <div style={{display: 'flex'}}>
                      <Autocomplete
                        id="dataset"
                        size="small"
                        fullWidth
                        required
                        ListboxProps={{ style: { maxHeight: 200 } }}
                        style={{ margin: 8, paddingRight: 17 }}
                        options={props.datasetsList}
                        onChange={(event, newValue) => {
                          handleChange(event, "dataset", newValue?._id || "");
                        }}
                        getOptionLabel={(option) => option.name || ""}
                        value={props.datasetsList.find(item => item._id === editingBatch?.dataset) || null}
                        getOptionSelected={(option, value) => option._id === value._id || true}
                        renderOption={(option, state) => (<Highlighter
                          searchWords={[state.inputValue]}
                          autoEscape={true}
                          textToHighlight={option.name}
                        />)}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label={t("batches.dataset")}/>
                        )}
                      />
                      <FormControlLabel
                        style={{ margin: 8, height: "auto" }}
                        control={<Checkbox/>}
                        label={t("batches.overwrite")}
                        checked={editingBatch?.overwrite}
                        onChange={(event) => {
                          handleChange(event, "overwrite", Boolean(event.target.checked))
                        }}
                      />
                    </div>
                  </Grid>
                  <Grid item sm={12}>
                  {['vectorize', 'annotate'].map(k => (
                    <FormControlLabel
                      key={k}
                      style={{ margin: 8, height: "auto" }}
                      control={<Checkbox/>}
                      label={t("batches."+ k)}
                      checked={Boolean(editingBatch?.[k])}
                      onChange={(event) => {
                        handleChange(event, k, Boolean(event.target.checked))
                      }}
                    />))}
                  </Grid>
                  {editingBatch.annotate ?
                    <Grid item sm={12}>
                      <SelectAutoModel {...{ autoModelList, editingBatch, handleChange, t }} options={ autoModelList } />
                    </Grid> : null}
                </Grid> : null}
              {type == "SPLIT_TRAIN_TEST" && modelsList.find(m => m._id == editingBatch.nlu_model_2)?.type != 'ASR_E2E' ?
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <Typography style={{ margin: "5px 0" }}>Split</Typography>
                    <TextField
                      id="split_percent"
                      value={editingBatch?.split_percent}
                      size="small"
                      onInput={event => {
                        const raw_value = event.target.value;
                        const value = raw_value[0] == '-' ? 0 : Number(raw_value) > 50 ? 50 : Number(raw_value);
                        handleChange(null, "split_percent", value);
                      }}
                      style={{ margin: 8, paddingRight: 17 }}
                      type="number"
                      label={t("batches.split_percent")}
                      variant="outlined"
                      fullWidth
                    />
                    {['split_adjust', 'split_backup', 'split_uniform'].map(k => (
                      <FormControlLabel
                        key={k}
                        style={{ margin: 8, height: "auto" }}
                        control={<Checkbox/>}
                        label={t("batches."+ k)}
                        checked={editingBatch && editingBatch[k]}
                        onChange={(event) => {
                          handleChange(event, k, Boolean(event.target.checked))
                        }}
                      />))}
                  </Grid>
                </Grid> : null}
              {["TRAIN_TEST", "SPLIT_TRAIN_TEST", "TRAIN_TEST_DEPLOY", "DEPLOY", "SPLIT_DEPLOY"].includes(type) ?
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <SelectNLUModel
                      {...{ modelsList, editingBatch, handleChange, t }}
                      name={type == "DEPLOY" || type == "SPLIT_DEPLOY" ? "NLU Model" : "Train"}
                      id="model"
                      fieldname="nlu_model_2"
                      options={ modelsList }
                    />
                    {type != "SPLIT_TRAIN_TEST" ? (
                      <>
                        <Grid item sm={12}>
                          <SelectTestModel {...{ testsList, editingBatch, handleChange, t }}/>
                        </Grid>
                        {type.match("DEPLOY") ?
                          <Grid item sm={12}>
                            <Typography style={{ margin: "5px 0" }}>Deploy</Typography>
                            <Autocomplete
                              id="condition"
                              size="small"
                              fullWidth
                              required
                              ListboxProps={{ style: { maxHeight: 200 } }}
                              style={{ margin: 8, paddingRight: 17 }}
                              options={["accuracy", "f1-score"]}
                              onChange={(event, newValue) => {
                                handleChange(event, "condition", newValue || "");
                              }}
                              getOptionLabel={(option) => option}
                              value={editingBatch?.condition || null}
                              getOptionSelected={(option, value) => option === value || true}
                              renderOption={(option, state) => (<Highlighter
                                searchWords={[state.inputValue]}
                                autoEscape={true}
                                textToHighlight={option}
                              />)}
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" label={t("batches.conditions")}/>
                              )}
                            />
                            <TextField
                              id="greater_then"
                              value={editingBatch?.greater_then}
                              size="small"
                              onInput={(event) => {
                                const value = event.target.value.indexOf("-") !== -1 ? 0 : Number(
                                event.target.value) > 1 ? 1 : Number(event.target.value);

                                handleChange(null, "greater_then", value);
                              }}
                              style={{ margin: 8, paddingRight: 17 }}
                              type="number"
                              label={t("batches.greater_then")}
                              variant="outlined"
                              inputProps={{ step: ".1" }}
                              fullWidth
                            />
                          </Grid> : null}
                      </>
                    ) : null}
                  </Grid>
                </Grid> : null}
              {type == "2_CLASSIFIERS" ?
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <SelectNLUModel
                      {...{ modelsList, editingBatch, handleChange, t }}
                      name="Train 1"
                      id="model1"
                      fieldname="nlu_model_1"
                      options={ modelsList }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectAutoModel
                      {...{ autoModelList, editingBatch, handleChange, t }}
                      options={ editingBatch?.nlu_model_1
                                ? autoModelList.filter(a => a.model == editingBatch?.nlu_model_1)
                                : autoModelList }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectNLUModel
                      {...{ modelsList, editingBatch, handleChange, t }}
                      name="Train 2"
                      id="model2"
                      fieldname="nlu_model_2"
                      options={editingBatch?.automodel
                        ? props.modelsList.filter(m =>
                            m.trainSet.find(ds =>
                              ds == autoModelList.find(a => a._id === editingBatch?.automodel).out_dataset)
                          )
                        : props.modelsList}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectTestModel {...{ testsList, editingBatch, handleChange, t }}/>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ margin: "5px 0" }}>Deploy</Typography>
                    <Autocomplete
                      id="condition"
                      size="small"
                      fullWidth
                      required
                      ListboxProps={{ style: { maxHeight: 200 } }}
                      style={{ margin: 8, paddingRight: 17 }}
                      options={["accuracy", "f1-score"]}
                      onChange={(event, newValue) => {
                        handleChange(event, "condition", newValue || "");
                      }}
                      getOptionLabel={(option) => option}
                      value={editingBatch?.condition || null}
                      getOptionSelected={(option, value) => option === value || true}
                      renderOption={(option, state) => (<Highlighter
                        searchWords={[state.inputValue]}
                        autoEscape={true}
                        textToHighlight={option}
                      />)}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label={t("batches.conditions")}/>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography style={{ margin: "5px 0" }}>&nbsp;</Typography>
                    <TextField
                      id="greater_then"
                      value={editingBatch?.greater_then}
                      size="small"
                      onInput={(event) => {
                        const value = event.target.value.indexOf("-") !== -1 ? 0 : Number(
                          event.target.value) > 1 ? 1 : Number(event.target.value);

                        handleChange(null, "greater_then", value);
                      }}
                      style={{ margin: 8, paddingRight: 17 }}
                      type="number"
                      label={t("batches.greater_then")}
                      variant="outlined"
                      inputProps={{ step: ".1" }}
                      fullWidth
                    />
                  </Grid>
                </Grid> : null}
            </fieldset>
          </DialogContent>
          <DialogActions>
            <Button onClick={doCancel}>{t("common.cancel")}</Button>
            <Button disabled={disabled()}
                    onClick={() => {
                      if (!receivedBatch) {
                        props.postBatch(editingBatch)
                          .then(doApply);
                      } else {
                        props.updateBatch({ ...editingBatch, _id: _id })
                          .then(doApply);
                      }
                    }} color="primary" autoFocus>
              {t("common.save")}
            </Button>
          </DialogActions>
        </Dialog>
        }
      </Fragment>
    );
  }
;

const mapStateToProps = (state) => ({
  projectId: state.settings.projectInfo.projectId,
});

export default connect(mapStateToProps)(EditDialog);
