import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  setIsLoading,
  getProjectId,
  getProjects,
} from "../../../features/settings";
import {
  fetchModelFilters,
} from "../../../features/modelFilters";
import { setMessageState } from "../../../features/messageInfo";
import { getData, postDataNew, putDataNew } from "../../../core/fetchService";
import { MESSAGE_STATUS, BTN, NLU_TYPE_AC_OPTS } from "../../../core/constants";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import YAML from "yaml";
import { EditConfig } from "./editConfig";
import { Controlled as CodeMirror } from "react-codemirror2";
import CloseBar from "../../components/dialogCloseBar";
import MultiSelect from "../../components/material-ui/MultiSelect";
import { usePrevious } from "../../../core/utils";
import notBackdropClicked from "../../components/helpers/notBackdropClicked";

const useStyles = makeStyles((theme) => ({
  fieldset: {
    margin: "10px",
    padding: "20px",
    borderRadius: 5,
    borderWidth: 1,
    color: "#C4C4C4"
  },
  dialogField: {
    margin: 8,
    paddingRight: 17
  },
  legend: {
    color: "#757575"
  }
}));

const useModalEditConfig = () => {
  const [isShowingEditConfig, setIsShowingEditConfig] = useState(false);

  function toggleEditConfig() {
    setIsShowingEditConfig(!isShowingEditConfig);
  }

  return {
    isShowingEditConfig,
    toggleEditConfig,
  };
};

function EditDialog(props) {
  const classes = useStyles();
  
  const projectId = useSelector(getProjectId);
  const projects = useSelector(getProjects);

  const initialEditModel = {
    project: projectId,
    name: null,
    description: '',
    trainSet: null,
    nluConfigText: null,
    nlu_train_url: null,
    nlu_deploy_url: null,
    model_config: null,
    type: null,
    include_data_schema: true,
  };

  const dispatch = useDispatch();
  const { isOpen, onClose, _id, editModel } = props;
  const modelFilters = props.modelFilters;
  const [configs, setConfigs] = useState(null);
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [isPreviewConfigOpen, setIsPreviewConfigOpen] = useState(false);
  const [projectDataSets, setProjectDataSets] = useState(null);
  const [editModelParams, setEditModelParams] = useState(editModel || initialEditModel);
  const prevModelType = usePrevious(editModelParams.type);

  const { isShowingEditConfig, toggleEditConfig } = useModalEditConfig();

  const { t } = useTranslation();

  async function fetchData() {
    try {
      const data = await getData(`/api/dataset?project=${projectId}`, false);
      if (data.error) {
        throw data.error;
      }
      if (data.datasets) {
        setProjectDataSets(data.datasets);
      }
    } catch (error) {
      dispatch(
        setMessageState({
          snackBarMessages: error.message,
          snackBarVariant: MESSAGE_STATUS.ERROR,
          snackBarState: true,
        }),
      );
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setEditModelParams(initialEditModel)
    }
  }, [isOpen]);

  useEffect(() => {
    getConfigs();
    
    dispatch(fetchModelFilters(projectId));
  }, []);

  useEffect(() => {
    if (!projectDataSets) {
      fetchData();
    }
  }, [projects, _id]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (event) => {
    setEditModelParams({ ...editModelParams, [event.target.id]: event.target.value });
  };

  const handleCheckboxChange = id => event => {
    setEditModelParams({ ...editModelParams, [id]: Boolean(event.target.checked) });
  };

  const handleChangeTrainSet = (event, value) => {
    setEditModelParams({ ...editModelParams, trainSet: (value || []).map((v) => v._id) || null })
  };

  const handleChangeConfig = (event, value) => {
    setEditModelParams({ ...editModelParams, model_config: value?._id ? value?._id : null })
  };

  const handleChangeNluType = (event, value) => {
    const types = {
      NLU_RASA: "nluConfig",
      NLU_NB: "similarityConfig",
      ASR_E2E: "asrConfig"
    };
    const project = projects?.find(p => p._id === projectId);
    const type = value?.value;
    const typeParams = project[types[type]];

    const nlu_train_url = type !== "NLU_NB"? typeParams?.train_url: typeParams?.server;
    const nlu_deploy_url = type !== "NLU_NB"? typeParams?.deploy_url: null

    setEditModelParams({ ...editModelParams, type, nlu_train_url, nlu_deploy_url });
  };

  const handleChangeFilters = (event, value) => {
      setEditModelParams({
        ...editModelParams, 
        filters: (value || []).map(({ _id }, index) => ({
          filter: _id,
          index,
        })),
      })
  };

  const closeEditConfig = () => {
    toggleEditConfig();
  };

  const doCancel = () => {
    onClose(BTN.CANCEL);
  };

  const doGetSchema = async () => {
    props.dispatch(setIsLoading(true));

    try {
      const response = await getData(`/api/project/${projectId}/data_schema`);

      if (response.error) {
        props.dispatch(
          setMessageState({
            snackBarMessages: response.error.message,
            snackBarVariant: MESSAGE_STATUS.ERROR,
            snackBarState: true,
          }),
        );
      } else {
        setEditModelParams({ ...editModelParams, nluConfigText: YAML.stringify(response) || null });
      }
    } catch (error) {
      props.dispatch(
        setMessageState({
          snackBarMessages: error.message,
          snackBarVariant: MESSAGE_STATUS.ERROR,
          snackBarState: true,
        }),
      );
    }

    props.dispatch(setIsLoading(false));
  };

  const doSave = async () => {
    const save = {
      method: _id ? putDataNew : postDataNew,
      uri: _id ? `/api/model/${_id}` : `/api/model`,
    };

    await save.method(
        save.uri,
        { model: editModelParams },
        dispatch,
        data => {
          dispatch(
              setMessageState({
                snackBarMessages: t("common.save_success"),
                snackBarVariant: MESSAGE_STATUS.SUCCESS,
                snackBarState: true,
              }),
          );
          // Success message only in one place (if needed will be deleted)

          onClose(BTN.SAVE);
    })
  };

  const getConfigs = async () => {
    props.dispatch(setIsLoading(true));

    try {
      const response = await getData(`/api/model_config`);

      setConfigs(response["model_configs"].filter(
        item => (item.scope === "custom" && item.project === projectId) || (item.scope === "public")).map(
        item => ({ _id: item._id, scope: item.scope, name: item.name, type: item.type })) || []);

      if (response.error) {
        props.dispatch(
          setMessageState({
            snackBarMessages: response.error.message,
            snackBarVariant: MESSAGE_STATUS.ERROR,
            snackBarState: true,
          }),
        );
      }
    } catch (error) {
      props.dispatch(
        setMessageState({
          snackBarMessages: error.message,
          snackBarVariant: MESSAGE_STATUS.ERROR,
          snackBarState: true,
        }),
      );
    }

    props.dispatch(setIsLoading(false));
  };

  const getConfig = async (id) => {
    props.dispatch(setIsLoading(true));

    try {
      const response = await getData(`/api/model_config/${id}`);

      if (response.error) {
        props.dispatch(
          setMessageState({
            snackBarMessages: response.error.message,
            snackBarVariant: MESSAGE_STATUS.ERROR,
            snackBarState: true,
          }),
        );
      } else {
        setSelectedConfig(response.model_config);
      }
    } catch (error) {
      props.dispatch(
        setMessageState({
          snackBarMessages: error.message,
          snackBarVariant: MESSAGE_STATUS.ERROR,
          snackBarState: true,
        }),
      );
    }

    props.dispatch(setIsLoading(false));
  };

  const handlePreviewConfig = () => {
    setIsPreviewConfigOpen(!isPreviewConfigOpen);
  };

  return (
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={isOpen}
        onClose={notBackdropClicked(doCancel)}
        scroll="paper"
        aria-labelledby="edit-model-dialog-title"
      >
        <CloseBar onClose={doCancel} title={_id ? t("train.edit") : t("train.new")}/>
        <DialogContent dividers={true}>
          {isPreviewConfigOpen &&
          <Dialog
            fullWidth={true}
            open={isPreviewConfigOpen}
            onClose={handlePreviewConfig}
            scroll="paper"
            maxWidth="sm"
          >
            <CloseBar onClose={handlePreviewConfig} title={selectedConfig?.name}/>
            <CodeMirror
              value={selectedConfig?.data}
              options={{
                mode: "yaml",
                lineNumbers: true,
              }}
              readOnly
            />
          </Dialog>
          }
          <TextField
            id="name"
            required
            size="small"
            className={classes.dialogField}
            label={t("train.model_name")}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            defaultValue={editModelParams.name || ""}
          />
          <TextField
            id="description"
            size="small"
            className={classes.dialogField}
            label={t("common.description")}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            defaultValue={editModelParams.description || ""}
          />
          <fieldset className={classes.fieldset}>
            <legend>Data</legend>
            <Grid container spacing={3}>
              <Grid item sm={12}>
                <MultiSelect
                  id="trainSet"
                  fullWidth
                  required
                  options={projectDataSets || []}
                  onChange={handleChangeTrainSet}
                  getOptionLabel={(option) => option?.name}
                  value={(editModelParams.trainSet || []).map((id) =>
                    (projectDataSets || []).find((ds) => ds._id === id),
                  )}
                  label={t("train.train_set")}
                />
              </Grid>
              <Grid item sm={12}>
                <Autocomplete
                  id="filters"
                  size="small"
                  multiple
                  fullWidth
                  required
                  filterSelectedOptions
                  options={modelFilters || []}
                  disableCloseOnSelect
                  onChange={handleChangeFilters}
                  getOptionLabel={(option) => option?.name}
                  value={(editModelParams.filters || []).map(({ filter }) =>
                    (modelFilters || []).find((mf) => mf._id === filter),
                  )}
                  renderOption={(option, { selected }) => (
                    <React.Fragment>{option?.name}</React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Filters"/>
                  )}
                />
                <div style={{ display: "-webkit-box", marginTop: "24px" }}>
                  {editModelParams.type == 'NLU_RASA' ?
                  <FormControlLabel
                    control={<Checkbox/>}
                    label={t("train.include_data_schema")}
                    checked={editModelParams.include_data_schema === undefined  // for old models
                        || editModelParams.include_data_schema}
                    onChange={handleCheckboxChange('include_data_schema')}
                  /> : null}
                  <Button
                    id="btnEdit"
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      doGetSchema()
                        .then(() => toggleEditConfig());
                    }}
                  >
                    {t("train.preview_data_schema")}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </fieldset>
          <fieldset className={classes.fieldset}>
            <legend className={classes.legend}>NLU</legend>
            <Autocomplete
              id="type"
              size="small"
              fullWidth
              required
              className={classes.dialogField}
              options={NLU_TYPE_AC_OPTS}
              onChange={handleChangeNluType}
              getOptionLabel={(option) => option.name || ""}
              value={NLU_TYPE_AC_OPTS.find(item => item.value === editModelParams.type) || null}
              getOptionSelected={(option, value) => option.value === value.value || true}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label={t("train.nlu_type")}/>
              )}
            />
            {editModelParams.type && configs && <div style={{ display: "flex" }}>
              <Autocomplete
                options={configs.filter(config => config.type === editModelParams.type) || []}
                groupBy={(option) => option.scope}
                id="config"
                required
                size="small"
                label={"Config"}
                onChange={handleChangeConfig}
                variant="outlined"
                getOptionLabel={(option) => option.name || ""}
                value={configs.find(config => config._id === editModelParams.model_config) || null}
                getOptionSelected={(option, value) => option._id === value._id || true}
                fullWidth
                renderInput={(params) => (
                  <TextField className={classes.dialogField} required {...params} variant="outlined" label="Config"/>
                )}
              />
              <Button
                disabled={!editModelParams.model_config}
                variant="outlined"
                style={{ height: "40px", marginTop: "8px" }}
                onClick={() => {
                  getConfig(editModelParams.model_config);
                  handlePreviewConfig();
                }}>
                {t("train.preview_config")}
              </Button>
            </div>}
            <TextField
              id="nlu_train_url"
              required
              size="small"
              className={classes.dialogField}
              label={t("train.train_test")}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              value={editModelParams.type && editModelParams.nlu_train_url || ""}
            />
            <TextField
              id="nlu_deploy_url"
              required
              size="small"
              className={classes.dialogField}
              label={t("train.runtime_url")}
              onChange={handleChange}
              variant="outlined"
              fullWidth
              value={editModelParams.type && editModelParams.nlu_deploy_url || ""}
            />
          </fieldset>
        </DialogContent>
        <DialogActions>
          <Button onClick={doCancel}>{t("common.cancel")}</Button>
          <Button
            disabled={['model_config', 'name', 'trainSet', 'type', 'nlu_train_url'].some(k => !editModelParams[k])}
            onClick={doSave} color="primary" autoFocus>
            {t("common.save")}
          </Button>
        </DialogActions>
        <EditConfig
          isOpen={isShowingEditConfig}
          onClose={closeEditConfig}
          nluConfigText={editModelParams.nluConfigText}
        />
      </Dialog>
  );
}

const mapStateToProps = (state) => ({
  projectId: state.settings.projectInfo.projectId,
  modelFilters: state.modelFilters.filters,
});

export default connect(mapStateToProps)(EditDialog);
