import React, { useState, useEffect, useRef } from "react";
import { useTranslation }                     from "react-i18next";
import { useSelector}                         from "react-redux";

import Dialog                       from "@material-ui/core/Dialog";
import DialogTitle                  from "@material-ui/core/DialogTitle";
import Paper                        from "@material-ui/core/Paper";
import IconButton                   from "@material-ui/core/IconButton";
import InputBase                    from "@material-ui/core/InputBase";
import DialogContent                from "@material-ui/core/DialogContent";
import List                         from "@material-ui/core/List";
import ListItem                     from "@material-ui/core/ListItem";
import ListItemIcon                 from "@material-ui/core/ListItemIcon";
import Checkbox                     from "@material-ui/core/Checkbox";
import ListItemText                 from "@material-ui/core/ListItemText";
import TextField                    from "@material-ui/core/TextField";
import DialogActions                from "@material-ui/core/DialogActions";
import Button                       from "@material-ui/core/Button";
import { makeStyles }               from "@material-ui/core/styles";
import Tooltip                      from "@material-ui/core/Tooltip";
import SearchIcon                   from "@material-ui/icons/Search";
import ChevronLeftIcon              from '@mui/icons-material/ChevronLeft';
import SavedSearchOutlinedIcon      from '@mui/icons-material/SavedSearchOutlined';
import OutlinedInput                from '@mui/material/OutlinedInput';
import { Collapse, Zoom }           from "@mui/material";

import NumberField                                from "../../components/material-ui/NumberField";

import { useProjectDataset }                      from "../../../core/utils";
import { getProjectId, getSelectedDataSetId }     from "../../../features/settings";

const useStyles = makeStyles({
    icon: {
        '& svg': {
            width: 18,
            height: 18
        },
    },
    input: {
        minWidth: 40,
        maxWidth: 120,
        fontSize: "14px",
        marginRight: "5px",
        height: "18px",
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: 1,
        },
    },
});

export function TextFilter({ selectedItems, filterBy, onFilter, onToggleOpen, id, placeholder, serverPagination }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const ZOOM_DELAY = 300;
  const ZOOM_OUT_DELAY = ZOOM_DELAY; // FIXME: remove this sync w/parent component (see searchesOpen in _TableSortLabel)
  const INIT = {
    SEARCH_OPEN: false,
    SEARCH_VALUE: selectedItems[filterBy]?.searchText || '',
    ZOOM_WIDTH: 0,
  };
  const [searchOpen, setSearchOpen] = useState(INIT.SEARCH_OPEN);
  const [searchValue, _setSearchValue] = useState(INIT.SEARCH_VALUE);
  const [zoomWidth, setZoomWidth] = useState(INIT.ZOOM_WIDTH);

  const ref = useRef();

  const projectId = useSelector(getProjectId);
  const selectedDataSetId = useSelector(getSelectedDataSetId);

  useProjectDataset(id, type => {
    if (type == 'dataset')
      return;
    setSearchOpen(INIT.SEARCH_OPEN); // onToggleOpen is called on the upper level
    _setSearchValue('');
    setTimeout(() => {
      setZoomWidth(INIT.ZOOM_WIDTH);
    }, ZOOM_OUT_DELAY);
  }, [projectId, selectedDataSetId]);

  const handleApplyFilter = sv => {
    onFilter({
      ...selectedItems,
      [filterBy]: { ...selectedItems[filterBy], searchText: sv !== undefined ? sv : searchValue },
    });
  };

  const setSearchValue = v => {
    _setSearchValue(v);
    if (!serverPagination)
      handleApplyFilter(v);
  };

  const handleOpenSearch = () => {
    setSearchOpen(true);
    onToggleOpen(true);
    setZoomWidth("auto");
    setTimeout(() => ref.current?.focus(), 500);
  };

  const handleCloseSearch = () => {
    setSearchOpen(false);
    setTimeout(() => {
      setZoomWidth(INIT.ZOOM_WIDTH);
      onToggleOpen(false);
    }, ZOOM_OUT_DELAY);
  };

  return (
    <div style={{display: "initial", whiteSpace: "nowrap", width: "100%"}}>
      {!searchOpen?
        <IconButton className={classes.icon} size="small" onClick={handleOpenSearch}>
          {selectedItems[filterBy]?.searchText ? <SavedSearchOutlinedIcon/> : <SearchIcon/>}
        </IconButton>
        :
        <IconButton className={classes.icon} size="small" onClick={handleCloseSearch}>
          <ChevronLeftIcon/>
        </IconButton>}
      <Zoom 
        timeout={ZOOM_DELAY}
        style={{width: zoomWidth, maxWidth: "90%"}}
        orientation="horizontal" in={searchOpen}>
        <div style={{display: "inline-flex"}}>
          <OutlinedInput
            inputRef={ref}
            className={classes.input}
            style={{ minWidth: 40, maxWidth: 120, fontSize: "14px", marginRight: "5px", height: "18px" }}
            placeholder={placeholder}
            value={searchValue}
            onChange={event => setSearchValue(event.target.value)}
            onKeyDown={event => {
              if (event.key == 'Escape')
                return searchValue ? setSearchValue('') : handleCloseSearch();
              if (event.key == 'Enter')
                return serverPagination && handleApplyFilter();
            }}/>
          {serverPagination ?
            <IconButton className={classes.icon} style={{paddingTop: 0}} size="small" onClick={() => handleApplyFilter()}>
            <SearchIcon/>
          </IconButton> : null}
        </div>
      </Zoom>
    </div>
  )
}

export function FilterDialog({ selectedItems: _selectedItems, headCell, rows, onClose }) {
  const filterBy = headCell._id;
  const comboData = headCell.filterComboData || headCell.comboData;
  const filterType = headCell.filterType || "text";
  const stamp = JSON.stringify(_selectedItems);
  const values = comboData
    || [...new Set(rows.map(r => r[filterBy]).filter(Boolean))].map(value => ({ value, label: value }));

  const [searchValue, setSearchValue] = useState("");
  const [selectedItems, setSelectedItems] = useState(_selectedItems);
  const [thresholdValue, setThresholdValue] = useState(_selectedItems[filterBy]?.threshold || 0);

  const { t } = useTranslation();

  const handleClearFilter = () => {
    const th = filterType == 'threshold' ? { threshold: 0 } : {};
    const filter = {
      ...selectedItems,
      [filterBy]: { ...selectedItems[filterBy], checkedAll: false, checked: [], ...th },
    };
    onClose(JSON.stringify(filter) == stamp ? undefined : filter);
  };

  const handleApplyFilter = () => {
    const th = filterType == 'threshold' ? { threshold: thresholdValue } : {};
    const filter = {
      ...selectedItems,
      [filterBy]: { ...selectedItems[filterBy], ...th },
    };
    onClose(JSON.stringify(filter) == stamp ? undefined : filter);
  };

  const handleListItemAllClick = () => {
    setSelectedItems({
      ...selectedItems,
      [filterBy]: {
        ...selectedItems[filterBy],
        checkedAll: !selectedItems[filterBy].checkedAll,
        checked: !selectedItems[filterBy].checkedAll ? values.map(v => v.value) : [],
      },
    });
  };

  const handleToggle = value => () => {
    const f = selectedItems[filterBy];
    setSelectedItems({
      ...selectedItems,
      [filterBy]: {
        ...f,
        checked: f.checked.includes(value) ? f.checked.filter(v => v != value) : [...f.checked, value],
        checkedAll: false,
      }
    });
  };

  return (
    <Dialog open={true} onClose={() => onClose()} aria-labelledby="simple-dialog-title">
      <DialogTitle style={{ padding: "12px 14px" }}>
        {filterType != "threshold" ?
          <Paper style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <IconButton disabled>
              <SearchIcon/>
            </IconButton>
            <InputBase
              style={{ flex: 1 }}
              placeholder={t("common.search")}
              value={searchValue}
              onChange={event => setSearchValue(event.target.value)}
            />
          </Paper> : null}
      </DialogTitle>
      <DialogContent>
        {filterType !== "threshold" ?
          <List>
            <ListItem autoFocus button onClick={handleListItemAllClick}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedItems[filterBy]?.checkedAll || false}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={t("common.select_all")}/>
            </ListItem>
            {(() => {
              const applySearchValue = values =>
                searchValue ? values.filter(v => String(v.label).match(new RegExp(searchValue, 'i'))) : values;

              return applySearchValue(values).map(({ value, label }, idx) => {
                const labelId = `checkbox-list-label-${value}`;
                return (
                  <ListItem key={idx} dense button onClick={handleToggle(value)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={(selectedItems[filterBy]?.checked || []).includes(value) || false}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={label}/>
                  </ListItem>
                );
              });
            })()}
          </List>
          :
            <NumberField
              label={t("common.threshold")}
              style={{ marginRight: 0, minWidth: "300px" }}
              value={thresholdValue}
              inputProps={{ min: 0, max: 1, step: ".1" }}
              onChange={event => setThresholdValue(event.target.value)}
              // onKeyDown={event => event.key == 'Enter' && handleApplyFilter()} - FIXME: FilterDialog popup reopens (does not close?) after Enter - seems, a conflict with other handler
            />
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClearFilter} color="primary">
          {t("common.reset")}
        </Button>
        <Button onClick={handleApplyFilter} color="primary" autoFocus>
          {t("common.ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
