import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Divider from "@material-ui/core/Divider";
import { useDispatch } from "react-redux";
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography,
  ListItemText,
  TextField,
  Toolbar,
  Tooltip,
  makeStyles,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
} from "@material-ui/core";
import { putDataNew } from "../../../core/fetchService";
import { Autocomplete } from "@material-ui/lab";
import { fetchModelFilters } from "../../../features/modelFilters";
import { AddBox as AddIcon, Delete as DeleteIcon } from "@material-ui/icons";
import Highlighter from "react-highlight-words";

const useStyles = makeStyles((theme) => ({
  autocomplete: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  },
  list: {
    marginTop: "20px",
    minHeight: "320px",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "4px",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Filter({
  type,
  options,
  filterValues,
  entitySelectPlaceholder,
  addButtonTooltip,
  selectedFilter,
  setSelectedFilter,
  projectId
}) {
  const initialSV = type.includes('exclude')? null: {from: [], to: null};
  const initialST = type.includes('exclude')? '': {from: '', to: ''};

  const classes = useStyles();
  
  const { t } = useTranslation();
  
  const dispatch = useDispatch();

  const [selectedValue, setSelectedValue] = useState(initialSV);
  const [searchText, setSearchText] = useState(initialST);

  useEffect(() => {
    setSelectedValue(initialSV);
    setSearchText(initialST);
  }, [selectedFilter]);

  useEffect(() => {
    if (type.includes('exclude')) searchOption(selectedValue)
  }, [selectedValue]);

  useEffect(() => {
    if (type.includes('replace')) searchOption(selectedValue.to, 'to');
  }, [selectedValue?.to]);

  const handleFilterUpdate = data => 
    putDataNew(`/api/filter/${selectedFilter?._id}`, { filter: {[type]: data} }, dispatch, data => {
      setSelectedFilter(data.filter);
      dispatch(fetchModelFilters(projectId));
  });

  const filterOptions = (options, inputName) => {
    const filterExistingValues = (filterArr, label) => !filterArr.includes(label) && !selectedValue.from.map(({ value, label }) => label).includes(label);

    if (type.includes('exclude')) return options.filter(({ value, label }) => !filterValues.includes(label) && label.includes(searchText));
    if (type.includes('replace')) {
        if (inputName == 'from') return options.filter(({ value, label }) => filterExistingValues(Object.keys(filterValues), label) && label.includes(searchText[inputName]));
        if (inputName == 'to') return options.filter(({ value, label }) => filterExistingValues(Object.values(filterValues), label) && label.includes(searchText[inputName]));
    } 
  };

  const handleSelectedValueChange = (value, inputName) => {
    if (type.includes('exclude')) setSelectedValue(value)
    if (type.includes('replace')) setSelectedValue({...selectedValue, [inputName]: value});
  };

  const handleAddClick = (value) => {
    if (type.includes('exclude')) handleFilterUpdate([...filterValues, selectedValue].filter(Boolean));
    if (type.includes('replace')) handleFilterUpdate({ ...filterValues, ...value });

    setSelectedValue(initialSV);
  };

  const handleDeleteClick = (value, keyToDelete) => {
    if (type.includes('exclude')) handleFilterUpdate(filterValues.filter((item) => item !== value));
    if (type.includes('replace')) handleFilterUpdate(
                                    Object.entries(filterValues)
                                    .filter(([key]) => key !== keyToDelete)
                                    .reduce((map, [key, value]) => ({ ...map, [key]: value }), {}),
                                 );
  };

  const searchOption = (value, inputName) => {
    if (type.includes('exclude')) setSearchText(value || '')
    if (type.includes('replace')) setSearchText({ ...searchText, [inputName]: value || ''})
  };
  
  return (
    <>
      {type.includes('exclude')?
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Toolbar disableGutters variant="dense">
            <Autocomplete
              onBlur={() => searchOption(selectedValue)}
              onClose={() => searchOption(selectedValue)}
              inputValue={searchText}
              value={selectedValue}
              className={classes.autocomplete}
              size="small"
              options={options}
              getOptionLabel={(option) => option.label || ''}
              getOptionSelected={(option, value) => option.label === value}
              renderOption={(option) =>
                    <Highlighter
                      searchWords={[searchText]}
                      autoEscape={true}
                      textToHighlight={option.label}/>
                    }
              renderInput={(params) => (
                <TextField
                  {...params}
                  onChange={e => searchOption(e.target.value)}
                  placeholder={entitySelectPlaceholder}
                  size="small"
                  variant="outlined"
                />
              )}
              filterOptions={options => filterOptions(options)}
              onChange={(e, value) => handleSelectedValueChange(value?.label || null)}
            />
            <IconButton
              onClick={() => handleAddClick(selectedValue)}
              disabled={!selectedValue}
            >
              <Tooltip title={addButtonTooltip || ""}>
                <AddIcon fontSize="small"/>
              </Tooltip>
            </IconButton>
          </Toolbar>
        </Grid>
        <Grid item xs={12} sm={12}>
          <List disablePadding className={classes.list}>
            {filterValues.map((item) => {
              return (
                <Fragment key={item}>
                  <ListItem>
                    <ListItemText>{item}</ListItemText>
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={() => handleDeleteClick(item)}
                      >
                        <DeleteIcon fontSize="small"/>
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider light />
                </Fragment>
              );
            })}
          </List>
        </Grid>
      </Grid>:
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Toolbar disableGutters variant="dense">
            <Grid container>
              <Grid style={{ display: "flex", minHeight: "10px" }} item xs={6} sm={6}>
                <Typography style={{ alignSelf: "center" }} className={classes.label}>
                  {t("filters.replace_from")}:
                </Typography>
                <Autocomplete
                  style={{ alignSelf: "center" }}
                  inputValue={searchText.from}
                  value={selectedValue.from}
                  multiple
                  filterOptions={(options) => filterOptions(options, 'from')}
                  onClose={() => searchOption('', "from")}
                  onBlur={() => searchOption('', "from")}
                  className={classes.autocomplete}
                  size="small"
                  options={options}
                  getOptionLabel={(option) => option.label || ''}
                  getOptionSelected={(option, value) => option.label == value.label}
                  renderOption={(option) =>
                    <Highlighter
                      searchWords={[searchText.from]}
                      autoEscape={true}
                      textToHighlight={option.label}/>
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={e => searchOption(e.target.value, "from")}
                      placeholder={entitySelectPlaceholder}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  onChange={(e, value) => handleSelectedValueChange(value || [], 'from')}
                />
              </Grid>
              <Grid style={{ display: "flex", minHeight: "10px" }} item xs={6} sm={6}>
                <Typography style={{ alignSelf: "center" }} className={classes.label}>
                  {t("filters.replace_to")}:
                </Typography>
                <Autocomplete
                  style={{ alignSelf: "center" }}
                  inputValue={searchText.to}
                  value={selectedValue.to}
                  filterOptions={(options) => filterOptions(options, 'to')}
                  onClose={() => searchOption(selectedValue.to, "to")}
                  onBlur={() => searchOption(selectedValue.to, "to")}
                  className={classes.autocomplete}
                  size="small"
                  options={options}
                  getOptionLabel={(option) => option.label || ''}
                  getOptionSelected={(option, value) => option.label === value}
                  renderOption={(option) =>
                    <Highlighter
                      searchWords={[searchText.to]}
                      autoEscape={true}
                      textToHighlight={option.label}/>
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={e => searchOption(e.target.value, "to")}
                      placeholder={entitySelectPlaceholder}
                      size="small"
                      variant="outlined"
                    />
                  )}
                  onChange={(e, value) => handleSelectedValueChange(value?.label || null, 'to')}
                />
              </Grid>
            </Grid>
            <IconButton
              onClick={() =>
                handleAddClick(selectedValue.from.map(({ value, label }) => label).reduce((acc, val) => ({ ...acc, [val]: selectedValue.to }), {}))
              }
              disabled={!(selectedValue.from.length !== 0 && selectedValue.to)}
            >
              <Tooltip title={addButtonTooltip}>
                <AddIcon/>
              </Tooltip>
            </IconButton>
          </Toolbar>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TableContainer className={classes.tableContainer}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow className={classes.headRow}>
                  <TableCell className={classes.headCell} align="center">
                    {t("filters.replace_from")}
                  </TableCell>
                  <TableCell
                    className={classes.headCell}
                    align="center"
                    colSpan={2}
                  >
                    {t("filters.replace_to")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(filterValues).map(([from, to]) => {
                  return (
                    <TableRow key={`${from}:${to}`}>
                      <TableCell
                        size={"small"}
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                      >
                        {from}
                      </TableCell>
                      <TableCell
                        size={"small"}
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                      >
                        {to}
                      </TableCell>
                      <TableCell
                        size={"small"}
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                      >
                        <IconButton onClick={() => handleDeleteClick(null, from)}>
                          <DeleteIcon/>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>}
    </>
  );
};