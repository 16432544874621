import { createSlice } from '@reduxjs/toolkit';
import { getDataNew as getData } from '../core/fetchService'
import { setMessageState } from './messageInfo'
import { MESSAGE_STATUS } from '../core/constants';
import { setIsLoading } from '../features/settings';

export const modelFilters = createSlice({
	name: 'modelFilters',
	initialState: {
    filters: [],
    currentFilter: null,
	},
	reducers: {
		setModelFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentModelFilter: (state, action) => {
      state.currentFilter = action.payload;
    }
	},
});

export const { setModelFilters, setCurrentModelFilter } = modelFilters.actions;

/** Note: not used yet (instead, state.modelFilters is used with the help of mapStateToProps
 *
 * export const getModelFilters = state => state.modelFilters.filters;
 * export const getCurrentModelFilter = state => state.modelFilters.currentFilter;
 */

export const fetchModelFilters = projectId => dispatch => 
  getData(`/api/filter?project=${projectId}`, dispatch, data => {
      dispatch(setModelFilters(data.filters));
  });

export default modelFilters.reducer;
