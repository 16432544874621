import { createSlice } from '@reduxjs/toolkit';
import { getDataNew as getData } from '../core/fetchService';
import { setMessageState } from './messageInfo'
import { MESSAGE_STATUS } from '../core/constants';
import { setIsLoading } from '../features/settings';

export const model = createSlice({
  name: 'model',
  initialState: {
    modelList: null,
  },
  reducers: {
    setModelList: (state, action) => {
      state.modelList = action.payload
    },
    setEditModelParams: (state, action) => {
      Object.keys(action.payload).forEach(key => {
        state[key] = action.payload[key];
      });
    },
    updateDataModel: (state, action) => {
      const { _id } = action.payload;
      state.modelList = state.modelList.map(model => {
        if (model._id !== _id) return model;
        //return {...model, status: status};
        return action.payload;
      });
    },
  },
});

export const { setModelList, updateDataModel } = model.actions;

export const fetchModelList = (obj) => async (dispatch, state) => {
  const { projectId } = obj;

  await getData(`/api/model?project=${projectId}`, dispatch, data => {
    dispatch(setModelList(data.models || []));
  });
}

export default model.reducer;
