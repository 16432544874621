import React, {
  useEffect, Fragment, useState, useRef
}                                         from "react";
import { useSelector, useDispatch }       from "react-redux";

import PropTypes  from "prop-types";
import clsx       from "clsx";

import { lighten, makeStyles }  from "@material-ui/core/styles";
import Grid                     from "@material-ui/core/Grid";
import Table                    from "@material-ui/core/Table";
import TableBody                from "@material-ui/core/TableBody";
import TableCell                from "@material-ui/core/TableCell";
import TableContainer           from "@material-ui/core/TableContainer";
import TableHead                from "@material-ui/core/TableHead";
import TablePagination          from "@material-ui/core/TablePagination";
import TableRow                 from "@material-ui/core/TableRow";
import TableSortLabel           from "@material-ui/core/TableSortLabel";
import Toolbar                  from "@material-ui/core/Toolbar";
import Typography               from "@material-ui/core/Typography";
import Paper                    from "@material-ui/core/Paper";
import TextField                from "@material-ui/core/TextField";
import Checkbox                 from "@material-ui/core/Checkbox";
import IconButton               from "@material-ui/core/IconButton";
import Tooltip                  from "@material-ui/core/Tooltip";
import MenuItem                 from "@material-ui/core/MenuItem";
import InputBase                from "@material-ui/core/InputBase";
import Link                     from "@material-ui/core/Link";
import AddBoxIcon               from "@material-ui/icons/AddBox";
import GetAppIcon               from "@material-ui/icons/GetApp";
import FilterIcon               from "@material-ui/icons/Filter";
import FilterNoneIcon           from "@material-ui/icons/FilterNone";
import UpdateIcon               from "@material-ui/icons/Update";
import Autocomplete             from "@material-ui/lab/Autocomplete";
import LinearProgress           from '@mui/material/LinearProgress';
import Popper                   from "@material-ui/core/Popper";

import {
  getTableStickyParamsKeyNames, useProjectDataset,
  useStickyState, getRunConfigOption, showError,
  useTranslation, downloadData,
}                                                     from "../../../core/utils";
import { DIALOG_USER_STATE }                          from "../../../core/constants";
import { getExpandedList, setExpandedList }           from "../../../features/annotationSettings";
import { getProjectId, getSelectedDataSetId }         from "../../../features/settings";
import { getFilter, setFilter, deleteFilter }         from "../../../features/filters";
import history                                        from "../../../other/history";

import { TextFilter, FilterDialog }   from "./tableFilter";
import ConfirmDialog                  from "../../components/confirmDialog";

function descendingComparator(x, y, orderBy, order) {
  const key = a => {
    const v = a[orderBy];
    return v || v === 0 ? (typeof v == 'string' ? v.toLowerCase() : v) : undefined;
  };
  const a = key(x);
  const b = key(y);
  
  return b < a ? -1
      : b > a ? 1
          : a == undefined ? (b == undefined ? 0 : (order == "desc" ? 1 : -1))
              : b == undefined ? (order == "desc" ? -1 : 1) : 0;
};

function getComparator(order, orderBy) {
  return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy, order)
      : (a, b) => -descendingComparator(a, b, orderBy, order);
};

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

function EnhancedTableHead({
  id, headCells, rows, serverPagination, selectedDataSetId, projectId, order, orderBy,
  onRequestSort, isCheckBoxTableCell, onFilter: _onFilter
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const [searchesOpen, setSearchesOpen] = useState({});
  const [filterBy, setFilterBy] = useState(null);

  const reduxFilter = useSelector(getFilter(id));

  const getSelectedItems = () => {
    const obj = {};

    (headCells || []).forEach(element => {
      const elementFilter = reduxFilter?.[element._id];

      obj[element._id] = { 
        checkedAll: elementFilter?.checkedAll || false,
        searchText: elementFilter?.searchText || "", 
        checked:    elementFilter?.checked    || [], 
        threshold:  elementFilter?.threshold  || ""
      }
    });
    return obj;
  }
  const selectedItems = getSelectedItems();

  const onFilter = filter => filter && (dispatch(setFilter({ id, filter })), _onFilter());

  const clearFiltering = () => {
    setFilterBy(null);

    if (reduxFilter) {
      dispatch(deleteFilter(id));
      _onFilter();
    }
  };

  useProjectDataset(id, type => {
    if (type == 'dataset')
      return;
    setSearchesOpen({});
    clearFiltering();
  },                                                          [projectId, selectedDataSetId]);

  /* disable clear filters on table reload (handleClickUpdateRow and/or new/updated rows)
  useEffect(() => { serverPagination || clearFiltering() },   [rows]);
  */
  
  const handleCloseFilterDialog = filter => {
    setFilterBy(null);
    onFilter(filter);
  };

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const isFilterOn = headcell =>
    reduxFilter?.[headcell] && (reduxFilter[headcell].checkedAll
                                || reduxFilter[headcell].checked?.length
                                || reduxFilter[headcell].threshold);

  const _TableSortLabel = headCell => {
    if (searchesOpen[headCell._id])
      return null;
    return headCell.sortable != false   // undefined or true
      ? (
        <TableSortLabel
          style={headCell.sortable ? {} : {whiteSpace: "normal"}}
          className={classes.columnName}
          active={orderBy === headCell._id}
          direction={orderBy === headCell._id ? order : "asc"}
          onClick={createSortHandler(headCell._id)}
        >
          {headCell.label}
          {orderBy === headCell._id ? (
            <span className={classes.visuallyHidden}>
              {order === "desc" ? "sorted descending" : "sorted ascending"}
            </span>
          ): null}
        </TableSortLabel>
      ) : typeof headCell.label == 'string' 
      ? (
        <span className={classes.columnName}>
          {headCell.label}
        </span>
      ): (
        <div className={classes.columnName}>
          {headCell.label}
        </div>
      );
  };

  return (
    <TableHead>
      {filterBy && rows && (
        <FilterDialog 
          selectedItems={selectedItems}
          headCell={headCells.find(h => h._id == filterBy)}
          rows={rows}
          onClose={handleCloseFilterDialog}
      />)}
      <TableRow style={{width: "100%", marginBottom: 10}}>
        {isCheckBoxTableCell && <TableCell padding="checkbox" style={{width: "5%"}}/>}
        {headCells.filter(cell => !cell.unpackToRow).map(headCell => (
          <TableCell
            className={classes.tableCell +' '+ classes.tableHeadCell}
            style={{width: headCell.width, whiteSpace: "nowrap"}}
            key={headCell._id}
            align="left"
            sortDirection={orderBy === headCell._id ? order : false}
          >
            {headCell.filterOn ?
              <Tooltip title={headCell.filterTooltip || t("common.filter_list")}>
                <IconButton size="small" onClick={() => setFilterBy(headCell._id)}>
                  {isFilterOn(headCell._id) ? <FilterIcon fontSize="small"/>: <FilterNoneIcon fontSize="small"/>}
                </IconButton>
              </Tooltip> : null}
            {headCell.customFilter || null}
            {headCell.textSearch &&
              <TextFilter
                filterBy={headCell._id}
                onToggleOpen={isOpen => setSearchesOpen({ ...searchesOpen, [headCell._id]: isOpen })}
                selectedItems={selectedItems}
                onFilter={onFilter}
                id={id}
                placeholder={headCell.label}
                serverPagination={serverPagination}
              />}
            {headCell.title ?
              <Tooltip title={headCell.title}>
                {_TableSortLabel(headCell)}
              </Tooltip> : _TableSortLabel(headCell)}
          </TableCell>
        ))}
        {<TableCell padding="checkbox"/>}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  id: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: PropTypes.array.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
    textAlign: "left",
  },
}));

const EnhancedTableToolbar = props => {
  const { toolBarName, newRowTitle, handleClickNewRow, handleClickUpdateRow, customHeaderButtons, downloadContent } = props;
  const { t } = useTranslation();
  const classes = useToolbarStyles();

  return (
    <Toolbar className={clsx(classes.root, {[classes.highlight]: false})}>
      <Typography className={classes.title} variant="h6" id="tableTitle">
        {toolBarName}
      </Typography>
      {customHeaderButtons && customHeaderButtons()}
      {downloadContent && <Tooltip title={"csv"}>
        <IconButton size="small" style={{marginLeft: 20}} onClick={downloadContent}>
          <GetAppIcon/>
        </IconButton>
      </Tooltip>}
      {handleClickNewRow && <Tooltip title={newRowTitle || t("common.new_row")}>
        <IconButton size="small" style={{marginLeft: 10}} onClick={handleClickNewRow}>
          <AddBoxIcon/>
        </IconButton>
      </Tooltip>}
      {handleClickUpdateRow && <Tooltip title={t("common.refresh")}>
        <IconButton size="small" style={{marginLeft: 10}} onClick={handleClickUpdateRow}>
          <UpdateIcon/>
        </IconButton>
      </Tooltip>}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  handleClickUpdateRow: PropTypes.func,
  handleClickNewRow: PropTypes.func,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  combo: {
    "&:before": {
      borderBottom: 0,
    },
  },
  columnName: {
    marginLeft: 3,
  },
  outlinedInput: {
    '& label.Mui-focused': {
      borderWidth: 1,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderWidth: 0,
      },
      '&:hover fieldset': {
        borderWidth: 1,
      },
      '&.Mui-focused fieldset': {
        borderWidth: 1,
      },
    },
  },
  tableHeadCell: {
    padding: '5px 0 5px 7px !important', // important to override tableCell.paddingLeft FIXME: re-consider
  },
  tableCell: {
    maxWidth: 500,
    paddingLeft: 10,
  },
  underlinedInput: {
    '& .MuiInputBase-root:before': {
      borderBottom: 0,
    },
    '& .MuiInputBase-root .MuiAutocomplete-endAdornment': {
      display: 'none',
    },
    '& .MuiInputBase-root:hover .MuiAutocomplete-endAdornment': {
      display: 'initial',
    },
  },
}));

const useAutocompleteStyles = makeStyles({
  option: {
    fontSize: "0.875rem",
  },
});

export function viewLink(row, cell, wrapper) {
  const style = cell.style && cell.style(row) || null;
  return (
    <Link href="#" style={style} onClick={e => cell.link(row, e)}>
      {(wrapper || (x => x ?? ''))(row[cell._id])}
    </Link>
  );
}

let said = {};

const selectProjectTableColumns = id => {
  const data = getRunConfigOption('projectTableColumns');
  if (data == 'show') {
    console.log('TABLE:', id);
    return cell => { console.log('\tCELL:', cell._id); return true };
  }
  const descr = data && JSON.parse(data)?.[id];
  const warn = (x,c) => {
    x && !said[c._id] && console.log(`////// Note: column '${c._id}' of table '${id}' is hidden!`);
    said[c._id] = x;
  }
  let x;
  return cell => cell.optional
    ? descr && descr.includes(cell._id)
    : !(descr && (x = descr.includes('!'+ cell._id), warn(x, cell), x));
};

/* const UE = useEffect; */

export default function EnhancedTable({
  id,
  headCells: _hcs,
  rows,
  viewCell = {},
  toolBarName,
  newRowTitle,
  tablePage = undefined,
  showPagination = true,
  handleClickUpdateRow = undefined,
  handleClickNewRow = undefined,
  handleFilteredRows = undefined,
  customBtns = undefined,
  checkBoxTableCell,
  handleRowItemChanged,
  handleRowItemBlur = undefined,
  downloadCSVName = undefined,
  customHeaderButtons = undefined,
  stickyState = true,
  fetchRows,
  pagingListInfo,
  orderBy = "rowNo",
  expandedRows,
  height = "100%",
  passedPage,
}) {
  const headCells = _hcs.filter(selectProjectTableColumns(id));
  const serverPagination = Boolean(fetchRows);

  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const projectId = useSelector(getProjectId);
  const selectedDataSetId = useSelector(getSelectedDataSetId);
  const isExpanded = useSelector(getExpandedList);
  const reduxFilter = useSelector(getFilter(id));

  const queryToPage = history.location.search.split('=')[1] - 1;
  const stickyParamsKeyNames = getTableStickyParamsKeyNames(projectId, selectedDataSetId, id);

  const [loading, setLoading] = useState(serverPagination);
  const [orderParams, setOrderParams] = useState({
    order: useStickyState("asc", stickyParamsKeyNames.order, stickyState)[0],
    orderBy: useStickyState(orderBy, stickyParamsKeyNames.orderBy, stickyState)[0]
  });
  const [pageParams, setPageParams] = useState({
    page: (passedPage && queryToPage) || 0,
    rowsPerPage: Number(localStorage.getItem(`rowsOnPage.${id}`)) || 20
  });
  const [filteredRows, setFilteredRows] = useState(rows);
  const [rowId, setRowId] = useState(null);
  const [selectedTextObj, setSelectedTextObj] = useState(null);

  const tableRef = useRef(null);
  const scrollToTop = () => tableRef.current?.scrollIntoView();

  const setPage = (page, force_reload) => {
    if (pageParams.page != page || force_reload)
      setPageParams({ ...pageParams, page });
  };

/*
  let N = 1;
  const useEffect = (f,d) => {
    const M = N++;
    UE(() => { console.log('['+ id +'] UE: '+ M, d); f() }, d);
  };
  console.log('['+ id +'] TTTTT',rows);
*/

  const _s = o => JSON.stringify(o);
  const _d = a => a && a.length && a;                                                     // empty arrays will all be equal in useEffect's deps comparison
  const sp = serverPagination;

  useProjectDataset(id, () => { setPage(0, true) },                 [projectId, selectedDataSetId]);

  useEffect(() => { tablePage && setPage(tablePage-1) },            [tablePage]);
  useEffect(() => { sp || applyFilters() },                         [sp || reduxFilter, sp || _d(rows)]);
  useEffect(() => { sp && getRows() },                              [sp && reduxFilter, sp && pageParams, sp && orderParams]);
  useEffect(() => {
    if (history.location.search)
      return;
    history.push('/'+ history.location.pathname.split('/')[1]
                 +'/'+ projectId
                 +(id == 'annotation'? '/'+ selectedDataSetId : ''));
  },                                                                []);

  const handleRequestSort = (event, property) => {
    const prevOrder = orderParams.orderBy == property ? orderParams.order : 'none';
    const order = { none: 'asc', asc: 'desc', desc: 'none' }[prevOrder];
    setOrderParams({
      order,
      orderBy: order == 'none' ? undefined : property,
    })
  };

  const setQuery = (newQuery) => {
    if (passedPage) {
      const pathname = history.location.pathname;
  
      history.push(`${pathname}${newQuery}`);
    };
  };

  const getFiltersForAPIRequest = () => {
    let filters = [];
    if (reduxFilter)
      Object.keys(reduxFilter).forEach(key => {
        const f = reduxFilter[key];
        if (f.checked?.length)
          filters.push([key == "text" ? "entities.slotId" : key, "in", f.checked]);
        if (f.searchText)
          filters.push([key == "intentId" ? "IntentName" : key, "like", f.searchText]);
        else if (f.threshold)
          filters.push(["threshold", "from", f.threshold]);
      });
    return filters;
  };

  const applyFilters = () => {
    let newRows = rows;
    if (reduxFilter) {
      Object.keys(reduxFilter).forEach(key => {
        const f = reduxFilter[key];
        if (/*!f.checkedAll &&*/ f.checked?.length)
          newRows = newRows.filter(r => f.checked.includes(r[key]));
        if (f.searchText)
          newRows = newRows.filter(r => String(r[key]).match(new RegExp(f.searchText, 'i')));
      });
    }
    setFilteredRows(newRows);

    handleFilteredRows && handleFilteredRows(reduxFilter && newRows);
  };

  const getRows = () => {
    setLoading(true);

    const paging = {
      page: pageParams.page + 1,
      limit: pageParams.rowsPerPage,
      dataset: selectedDataSetId,
      sort: { [orderParams.orderBy]: orderParams.order == "asc" ? 1 : -1 },
      filters: getFiltersForAPIRequest(),
    };

    fetchRows(paging)
      .finally(() => setLoading(false));
  };

  const handleChangePage = (event, newPage) => {
    setQuery(newPage? `?page=${newPage + 1}`: ``);

    setPage(newPage);
    scrollToTop()
  };

  const handleChangeRowsPerPage = event => {
    localStorage.setItem(`rowsOnPage.${id}`, parseInt(event.target.value, 10).toString());

    setQuery(``);

    setPageParams({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  const onExpand = (_id, stillOpen) => {
    dispatch(setExpandedList({ _id, stillOpen }));
  };

  const { page, rowsPerPage } = pageParams;

  /* TODO: review */
  const emptyRows = serverPagination ? 0 : rowsPerPage - Math.min(rowsPerPage, rows.length - page*rowsPerPage);

  const __rows = serverPagination
    ? rows
    : (stableSort(filteredRows, getComparator(orderParams.order, orderParams.orderBy))
       .slice(page*rowsPerPage, (page+1)*rowsPerPage));
  const _rows = unpackCells(createRowSpans(__rows, headCells), headCells);

  function Pagination() {
    const [pageBeforeEnter, setPageBeforeEnter] = useState(pageParams.page + 1);

    const numRows = serverPagination ? pagingListInfo?.totalDocs || 0 : filteredRows.length;
    const totalPages = serverPagination
      ? pagingListInfo?.totalPages || 1
      : numRows > 0 ? Math.ceil(numRows/pageParams.rowsPerPage) : 1;

    useEffect(() => {
      if (pageParams.page > totalPages - 1 && !loading) {
        setQuery('');
        setPage(0);
        showError(dispatch, t)('common.error_missing_page', {
          page_num: pageParams.page + 1,  page_size: numRows,  num_pages: totalPages
        });
      };
    }, [pageParams.page, totalPages, loading])

    return showPagination ? (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 40]}
          component="div"
          count={numRows}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={t("table.rows_per_page")}
          nextIconButtonText={t("table.next_page")}
          backIconButtonText={t("table.prev_page")}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelDisplayedRows={({ from, to, count }) => {
            return <>
              {`${from}-${to === -1 ? count : to} ${t("table.of")} ${count !== -1 ? count : to}`}
              <span style={{ marginLeft: 15, marginRight: 10 }}>{t("table.page")}:</span>
              <input
                style={{ border: "none", minWidth: 50, width: 19 + 7*String(totalPages).length }}
                onFocus={e => e.target.select()}
                type="number"
                onKeyPress={e => {
                  if (e.charCode == 13) {
                    handleChangePage(
                      e,
                      pageBeforeEnter && pageBeforeEnter - 1 >= 0 && pageBeforeEnter - 1 < totalPages?
                        pageBeforeEnter - 1:
                        page
                    );
                  }
                }}
                onBlur={() => {
                  setPageBeforeEnter(page + 1)
                }}
                onChange={e => {
                  const pageNum = e.target.value !== '0'? Number(e.target.value) || '': 0;

                  setPageBeforeEnter(pageNum)
                }}
                value={pageBeforeEnter}
              />
              {t("table.of")}
              <span style={{ marginLeft: 10 }}>{totalPages}</span>
            </>;
          }}
        />
    ) : null;
  }

  if (serverPagination) {
    if (handleClickUpdateRow) {
      console.error(String(new Error('No handleClickUpdateRow expected!')));
      return <p>Internal error! // see console</p>;
    }
    handleClickUpdateRow = () => {
      const PRESERVE_FILTERS = true; // disable clear filters on handleClickUpdateRow and/or new/updated rows
      if (PRESERVE_FILTERS) {
        getRows();
      } else {
        if (!reduxFilter)
          getRows();
        else
          dispatch(deleteFilter(id));
      }
    }
  }

  if (serverPagination && handleFilteredRows)
    return 'handleFilteredRows is not implemented for serverPagination mode';

  const downloadContent = downloadCSVName ? () => {
    const getCellData = row => cell => {
      const data = row[cell._id];
      return data && cell.comboData
      ? cell.comboData.find(d => d.value == data)?.label || '?'
      : data;
    };
    downloadData(rows.map(row => headCells.map(getCellData(row)).join('\t')).join('\n'), downloadCSVName);
  } : null;

  return (
    <div className={classes.root} ref={tableRef}>
      <Paper className={classes.paper}>
        {toolBarName ?
          <EnhancedTableToolbar
            {...{
              toolBarName,
              newRowTitle,
              handleClickNewRow,
              handleClickUpdateRow,
              customHeaderButtons,
              downloadContent,
            }}
          />
          : null}
        {getRunConfigOption('paginationAbove') && <Pagination/>}
        <TableContainer style={{height, overflow: "hidden", scrollbarWidth: "none"}}>
          <Table
            className={classes.table}
            size={"small"}
          >
            <EnhancedTableHead
              id={id}
              headCells={headCells}
              rows={rows}
              serverPagination={serverPagination}
              selectedDataSetId={selectedDataSetId}
              projectId={projectId}
              order={orderParams.order}
              orderBy={orderParams.orderBy}
              onRequestSort={handleRequestSort}
              isCheckBoxTableCell={!!checkBoxTableCell}
              onFilter={() => setPage(0)}
            />
            {!loading
              ? <TableBody>
                {_rows.map((row, index) => {
                  let key = `tablebody-fragment-${row._id}`;
                  if (row.__unpacked)
                    key += '-[unpacked]';
                  if (row.sub_id != undefined)
                    key += '-'+ row.sub_id;
                  return (
                    <Fragment key={key}>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        {checkBoxTableCell && checkBoxTableCell(row._id, row.name, (pageParams.page*pageParams.rowsPerPage) + index)}
                        {
                          headCells.filter(cell => row.__spans?.[cell._id] != -1)
                            .filter(cell => Boolean(cell.unpackToRow) == Boolean(row.__unpacked))
                            .map((cell, idx) => {
                              const props = {
                                ...(row.__spans?.[cell._id] && !row.__unpacked ? { rowSpan: row.__spans[cell._id] } : {}),
                                ...(cell.unpackToRow ? {
                                  colSpan: cell.unpackToRow.columns.filter(() => true/* TODO is_col_shown*/).length
                                } : {}),
                              };
                              return (
                            <TableCell
                              key={cell._id + idx} /* nice error w/o cell._id (!) */
                              {...props}
                              className={row?.__classes?.[cell._id] +' '+ classes.tableCell}
                              align={cell.align}
                              style={{ wordWrap: "break-word"}}
                            >
                              {(() => {
                                if (viewCell[cell._id])
                                  return viewCell[cell._id](row, cell, { setSelectedTextObj });

                                if (cell.customTextField || row.__customTextField?.[cell._id]) {
                                  return <CustomTextField
                                    id={cell._id + index}
                                    className={cell.classes?.customTextField}
                                    regularExpr={cell.regularExpr || null}
                                    value={row[cell._id]}
                                    multiline={cell.multiline || false}
                                    disabled={cell.disabled || false}
                                    onBlur={(value) => {
                                      handleRowItemChanged({ ...row, [cell._id]: value, rowName: cell._id });
                                    }}
                                  />
                                }

                                const comboData = cell.comboData || row.__comboData?.[cell._id];
                                if (comboData) {
                                  if (row[cell._id] == null) // undefined/null OR loading value...
                                    return null;
                                  const wrong_value = { value: null, label: '[error: unknown]: '+ row[cell._id] };
                                  const value = comboData.find(d => d.value == row[cell._id]);
                                  const options = row[cell._id] && !value ? [...comboData, wrong_value] : comboData;

                                  const customPopper = cell.anotherPopper ? { PopperComponent: props => (
                                    <Popper {...props}
                                      style={{ width: "fit-content", backgroundColor: "#fff" }}
                                      placement='bottom-start'
                                    />
                                  )} : {};
                                  return (
                                    <Tooltip arrow title={(cell.titleAuthor ? row.author : value?.title) || ""}>
                                      <Autocomplete 
                                        size="small"
                                        style={{ fontSize: "0.875rem", width: "100%" }}
                                        options={options}
                                        // filterOptions={options => options.filter(o => o != wrong_value)} - prevents completion
                                        getOptionLabel={option => option.label}
                                        disabled={typeof cell.disabled == "function" ? cell.disabled(row) : cell.disabled}
                                        autoComplete
                                        disableClearable
                                        autoHighlight
                                        fullWidth
                                        {...customPopper}
                                        renderOption={(option, state) =>
                                          <Typography selected={state.selected}>
                                            {option.title ? <Tooltip title={option.title || ""}>
                                              <span>{option.label}</span>
                                            </Tooltip> : option.label}
                                          </Typography>
                                        }
                                        value={row[cell._id] ? value || wrong_value : null}
                                        onChange={(event, value) => handleRowItemChanged({
                                          ...row,
                                          [cell._id]: value?.value || null,
                                          rowName: cell._id,
                                        })}
                                        renderInput={(params) => {
                                          const newParams = {
                                            ...params,
                                            InputProps: { ...params.InputProps, style: { fontSize: "0.875rem" } },
                                          };
                                          return <TextField
                                            className={classes.underlinedInput}
                                            {...newParams}
                                            variant="standard"
                                            id={cell._id + index}
                                            size="small"
                                            fullWidth
                                          />
                                        }}/>
                                    </Tooltip>
                                  );
                                }

                                if (cell.dateTime)
                                  return row[cell._id] && new Date(row[cell._id]).toLocaleString();

                                if (cell.checkBox) {
                                  return <Checkbox
                                    checked={row[cell._id]}  //it's controlled: rows changed after switch
                                    onChange={(event) => handleRowItemChanged(
                                      { ...row, [cell._id]: event.target.checked, rowName: cell._id })}
                                  />
                                }

                                if (cell.link)
                                  return viewLink(row, cell);

                                const cell_value = row[cell._id] ?? "";
                                const rowSpan = row.__spans?.[cell._id];
                                const limit = cell.limit && ((rowSpan != -1 && rowSpan) ?? 2)*cell.limit/2;

                                if (limit && cell_value.length > limit && !cell.unpackToRow)
                                  return <TrimText text={cell_value} limit={limit}/>

                                return cell_value;
                              })()}
                            </TableCell>
                          )})
                        }

                        {customBtns ? <TableCell padding="checkbox" align="right">
                          <div>
                            {customBtns(row.name, row._id, row)}
                          </div>
                        </TableCell> : null}
                      </TableRow>

                      {expandedRows && isExpanded.find(id => id == row._id)
                          && expandedRows({
                            key: `${id}-subtable-${index}-${row._id}`,
                            row,
                            selectedTextObj: selectedTextObj && selectedTextObj.rowId == row._id ? selectedTextObj : "",
                            refresh: getRows,
                          })}
                    </Fragment>
                  )})}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53*emptyRows }}>
                    <TableCell padding="checkbox" colSpan={6}/>
                  </TableRow>
                )}
              </TableBody>
              :
              <TableBody>
                <TableRow>
                  <TableCell padding="checkbox" colSpan={7}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            }
          </Table>
        </TableContainer>
        <Pagination/>
      </Paper>
    </div>
  );
}

function unpackCells(rows, headCells) {
  if (!headCells)
    return rows;
  // only unpacking a single cell from a row is implemented now
  const k = headCells.find(c => c.unpackToRow)?._id;
  if (!k)
    return rows;
  // Note: unpack is meant to work after span creation and only with spans not intersecting unpacked rows!
  return rows.map(r => r.__spans?.[k] == -1 ? r : [{
    _id: r._id,
    __spans: r.__spans,
    [k]: r[k],
    __unpacked: true,
  }, r]).flat();
}

function createRowSpans(rows, headCells) {
  if (!headCells)
    return rows;
  const spans = headCells.reduce((a, cell) => { a[cell._id] = null; return a }, {});
  const set_span = (row, k, size) => (row.__spans = (row.__spans || {}))[k] = size;
  return rows.concat({}).map(_row => {
    const row = {..._row};
    headCells.forEach(cell => {
      if (!cell.mergeEqualRows)
        return;
      const k = cell._id;
      const span = spans[k];
      if (!span) {
        spans[k] = { row, size: 1 };
      } else if (row[k] == span.row[k]) {
        set_span(row, k, -1);
        ++ span.size;
      } else  {
        if (span.size != 1)
          set_span(span.row, k, span.size);
        span.row = row;
        span.size = 1;
      }
    });
    return row;
  }).slice(0,-1);
}

function CustomTextField(props) {
  const { id, className, regularExpr, value: _v, multiline, disabled, onBlur } = props;

  const classes = useStyles();
  const [value, setValue] = useState(_v);

  useEffect(() => setValue(_v), [_v]);

  return <TextField 
    className={classes.outlinedInput + (className ? ' '+ className : '')}
    size="small"
    id={id}
    onChange={(event) => {
      let value = event.target.value;
      if (regularExpr) {
        value = value.replace(regularExpr, "");
      }

      setValue(value);
    }}
    onBlur={() => onBlur(value)}
    value={value || ""}
    fullWidth
    multiline={multiline || false}
    disabled={disabled || false}
    variant="outlined"
    inputProps={{ style: { fontSize: "0.9rem" } }}
  />;
};

EnhancedTable.propTypes = {
  id: PropTypes.string.isRequired,
};

function TrimText(props) {
  const { text, limit } = props;
  const [show, setShow] = useState(false);
  return (
    <>
      {text.slice(0, limit)}
      {show && <span>{text.slice(limit)}</span>}
      <button style={{marginLeft: 5, opacity: 0.4}} onClick={() => setShow(!show)}>
        {show ? '<<' : '...'}
      </button>
    </>
  )
}
