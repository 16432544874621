import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import {withStyles} from "@material-ui/core";

import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { connect } from 'react-redux';
import { getMessageState, closeMessageInfo } from '../../../features/messageInfo';
let classNames = require('classnames');

const styles = theme => ({
    success: {
        backgroundColor: '#43a047',
    },
    error: {
        backgroundColor: '#d32f2f',
    },
    warning: {
        backgroundColor: '#ffa000',
    },
    info: {
        backgroundColor: '#2196f3',
    },
    icon: {
        fontSize: 20,
    },
    iconSmall: {
        fontSize: 10,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 20,
    },
});

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
};

function MySnackbarContent(props) {
    const { classes, message, variant, index, onClose, prevMessage, nextMessage, ...other } = props;
    const Icon = variantIcon[variant] || CheckCircleIcon;
    
    return (
        <SnackbarContent
            className={classes[variant]}
            aria-describedby="client-snackbar"
            message={
                <div style={{ display: 'flex' }}>
                    {prevMessage &&
                    <IconButton onClick={prevMessage} color='inherit' size='small'>
                        <ArrowUpwardIcon className={classes.iconSmall} />
                    </IconButton>}
                    {nextMessage &&
                    <IconButton onClick={nextMessage} color='inherit' size='small'>
                        <ArrowDownwardIcon className={classes.iconSmall} />
                    </IconButton>
                    }
                    <span id="client-snackbar" className={classes.message}>
                        {index ? <span style={{ marginRight: 10 }}>{'['+ index +']'}</span> : ''}
                        <Icon className={classNames(classes.icon, classes.iconVariant)} />
                        {message}
                    </span>
                </div>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

class SnackbarMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        };
    };

    snackBarClose = () => {
        this.props.dispatch(closeMessageInfo(true));
    };

    adjcMessage = (delta, messages) => (
        messages[this.state.index + delta] && (() => this.setState({ index: this.state.index + delta }))
    );
 
    render() {
        const { snackBarState, snackBarMessages } = this.props;

        return (
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={snackBarState}
                onClose={this.snackBarClose}
                autoHideDuration={null}
            >
                <MySnackbarContentWrapper
                    onClose={this.snackBarClose}
                    message={snackBarMessages[this.state.index]?.message}
                    variant={snackBarMessages[this.state.index]?.variant}
                    index={this.state.index}
                    prevMessage={this.adjcMessage(1, snackBarMessages)}
                    nextMessage={this.adjcMessage(-1, snackBarMessages)}
                />
            </Snackbar>
        );
    }
}

const mapStateToProps = state => {
    return getMessageState;
};

export default connect(mapStateToProps)(SnackbarMessage);
