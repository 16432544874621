import { createSlice } from '@reduxjs/toolkit';
import { getDataNew } from '../core/fetchService'
import { setMessageState } from './messageInfo'
import { MESSAGE_STATUS } from '../core/constants';
import { setIsLoading, setLookupList } from '../features/settings';
import { setBackDropOpen } from '../features/messageInfo';

export const annotationSettings = createSlice({
  name: 'annotationSettings',
  initialState: {
    slotsList: null,
    intentsList: null,
    headCells: null,
    annotationRefreshData: null,
    isExpanded: [0] //only one entity, replace later
  },
  reducers: {
    setIntentsList: (state, action) => {
      state.intentsList = action.payload;
    },
    setHeadCell: (state, action) => {
      state.headCells = action.payload;
    },
    setSlotsList: (state, action) => {
      state.slotsList = action.payload;
    },
    setExpandedList: (state, action) => {
      const { _id, stillOpen } = action.payload;
      state.isExpanded = state.isExpanded.find(id => id === _id) && !stillOpen ? state.isExpanded.filter(id => id !== _id) : [...state.isExpanded, _id];
    },
    expandAllRows: (state, action) => {
      state.isExpanded = action.payload;
    },
    collapseAllRows: (state) => {
      state.isExpanded = [];
    },
  },
});

export const {
  setIntentsList,
  setHeadCell,
  setSlotsList,
  setExpandedList,
  expandAllRows,
  collapseAllRows,
} = annotationSettings.actions;

export const getIntentsList = state => state.annotationSettings.intentsList;
export const getHeadCell = state => state.annotationSettings.headCells;
export const getSlotsList = state => state.annotationSettings.slotsList;
export const getExpandedList = state => state.annotationSettings.isExpanded;

export const fetchSlots = (obj, callback) => async (dispatch) => {
  const { projectId } = obj;
  await getDataNew(`/api/slot?project=${projectId}`, dispatch, data => {
    dispatch(setSlotsList(data.slots));
    dispatch(setLookupList(data.lookups));
    if (callback) callback(data);
  })
}

export const fetchIntents = (obj, callback) => async (dispatch) => {
  const { projectId } = obj;
  await getDataNew(`/api/intent?project=${projectId}`, dispatch, data => {
    dispatch(setIntentsList(data.intents));
    if (callback) callback(data.intents);
  })
}

export const fetchHeadCellData = (obj, callback) => async (dispatch) => {
  try {
 // dispatch(setBackDropOpen(true));
    await dispatch(fetchSlots(obj, (data) => {
      const slots = data?.slots;
      dispatch(fetchIntents(obj, (intents) => {
        if (callback) callback({ slotsList: slots, intentsList: intents });
      }));
    }));
    dispatch(setBackDropOpen(false));
  }
  catch{
    dispatch(setBackDropOpen(false));
  }
}

export default annotationSettings.reducer;
