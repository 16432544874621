import React from 'react';
import Toolbar from "@mui/material/Toolbar";
import { Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";

export default function CloseBar(props) {
  const {onClose, title} = props

  return (
    <AppBar style={{ background: "#3F51B5" }} sx={{ position: "relative" }}>
      <Toolbar>
        <Typography sx={{ flex: 1 }} variant="h6" component="div">
          {title}
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon/>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
