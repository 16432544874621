import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { setIsLoading, fetchAutoModelList } from "../../../features/settings";
import { setMessageState } from "../../../features/messageInfo";
import { getData, postData, deleteDataNew, putData, putDataNew } from "../../../core/fetchService";
import { MESSAGE_STATUS, DIALOG_USER_STATE } from "../../../core/constants";
import EnhancedTable from "../../components/projectTable";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import ConfirmDialog from "../../components//confirmDialog";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { setDefaultRowsOnPage, CHK_FAIL } from "../../../core/utils";
import { withTranslation, useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import { withStyles } from "@material-ui/styles";
import CloseBar from "../../components/dialogCloseBar";

import IconButton from "../../components/material-ui/IconButton";

import {
  viewStatus, linkStyle, Runnable,
}                                         from "../../components/taskStatus";

import notBackdropClicked from "../../components/helpers/notBackdropClicked";

function viewAutoStatus(row, cell) {
  return viewStatus(row, cell, {
    s_obj: row,
    percent: row.progress?.percent,
    in_progress: 'Auto',
  });
}

const styles = (theme) => ({
  dialogField: {
    marginTop: 10
  },
  tooltip: {
    marginRight: 10
  }
});

function EditDialog(props) {
  const { isOpen, onClose, automodels, row, classes } = props;
  const { datasets, models } = automodels;
  const [modelData, setModelData] = useState({});
  const [overwriteInput, setOverwriteInput] = useState(false);
  const scroll = "paper";
  const { t } = useTranslation();

  useEffect(() => {
    if (row) {
      setModelData({ name: row.name, dataset: row.dataset, model: row.model, out_dataset: row.out_dataset })
      setOverwriteInput(row.dataset && row.dataset == row.out_dataset);
    } else {
      setModelData({})
    }
  }, [row]);

  const setInputDataset = (event, value) => {
    const dataset = value?._id || null;
    const other = {};
    if (overwriteInput) {
      other.out_dataset = dataset;
    } else {
      if (dataset && dataset == modelData.out_dataset)
        other.out_dataset = null;
    }
    setModelData({ ...modelData, dataset, ...other });
  };

  const changeOverwriteInput = (event) => {
    const ow = event.target.checked;
    const out_dataset = ow ? modelData.dataset : null;
    setOverwriteInput(ow);
    setModelData({ ...modelData, out_dataset});
  };

  return (
    <Fragment>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={isOpen}
        onClose={notBackdropClicked(() => onClose(null))}
        scroll={scroll}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <CloseBar onClose={() => onClose(null)} title={row ? t("auto.edit_auto") : t("auto.add_auto")}/>
        <DialogContent dividers={scroll === "paper"} style={{ overflow: "hidden" }}>
          <TextField
            required
            id="autoName"
            size="small"
            className={classes.dialogField}
            label={t("common.name")}
            onChange={(event, value) => setModelData({ ...modelData, name: event.target.value })}
            variant="outlined"
            fullWidth
            defaultValue={row?.name || null}
          />
          <Autocomplete
            key="dataSet"
            id="dataSet"
            size="small"
            options={datasets?.filter(d => d._id != modelData.out_dataset)}
            onChange={setInputDataset}
            getOptionLabel={(option) => option.name}
            value={datasets?.find(d => d._id == modelData.dataset) || null}
            className={classes.dialogField}
            renderInput={(params) => (
              <TextField required {...params} variant="outlined" label={t("auto.dataset")}/>
            )}
          />
          <Autocomplete
            key="model"
            id="model"
            size="small"
            options={models}
            onChange={(event, value) => setModelData({ ...modelData, model: value?._id || null })}
            getOptionLabel={(option) => `${option.name} ${option.status}`}
            defaultValue={(models && models.find(d => d._id === row?.model)) || null}
            className={classes.dialogField}
            renderInput={(params) => (
              <TextField required {...params} variant="outlined" label={t("auto.model")}/>
            )}
          />
          <fieldset style={{
            marginTop: "10px",
            padding: "20px",
            borderRadius: 5,
            borderWidth: 1,
          }}>
            <legend style={{color: "#757575"}}>{t("auto.save_to")}</legend>
            <FormControlLabel
              control={<Checkbox/>}
              label={t("auto.overwrite_input")}
              checked={overwriteInput}
              onChange={changeOverwriteInput}
            />
            {overwriteInput ?
              <span style={{ marginLeft: "50px", color: "red" }}>{t("auto.overwrite_warning")}</span> : null}
            {overwriteInput ? null : <>
              {datasets && <Autocomplete
                key="out_dataset"
                id="out_dataset"
                size="small"
                options={datasets?.filter(d => d._id != modelData.dataset)}
                onChange={(event, value) => setModelData({ ...modelData, out_dataset: value?._id || null })}
                getOptionLabel={(option) => option.name}
                value={datasets?.find(d => d._id == modelData.out_dataset) || null}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label={t("auto.dataset")}/>
                )}
                style={{ marginTop: "20px" }}
              />}
            </>}
            <Typography style={{ margin: (overwriteInput ? 20 : 30)+"px 0 10px" }}>
              {t("auto.dataset_import_settings")}
            </Typography>
            {["update_intents"].map(name =>
            <FormControlLabel
              key={name}
              style={{ marginLeft: "10px", height: "auto", width: "100%" }}
              control={<Checkbox required/>}
              label={t("datasets."+ name)}
              checked={(row && row[name]) || modelData[name] || false}
              onChange={(event, checked) => setModelData({ ...modelData, [name]: checked || false })}
            />
            )}
          </fieldset>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(null)} color="primary" autoFocus>
            {t("common.cancel")}
          </Button>
          <Button onClick={() => onClose(modelData)} color="primary" disabled={!modelData.dataset || !modelData.out_dataset || !modelData.model || !modelData.name}>
            {t("common.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

function ConfirmOutputDialog(props) {
  const { isOpen, onClose, automodels, row, classes } = props;
  const { datasets } = automodels;
  const [ modelData, setModelData ] = useState({});
  const [ outputToNew, setOutputToNew ] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!row) return;
    setModelData({ out_dataset: row.out_dataset })
    setOutputToNew(false);
  }, [row]);

  const saveAndStart = () => {
    if (outputToNew)
      putDataNew(`/api/automodel/${row._id}/setup_out_dataset`,
                 { name: modelData.new_name },
                 dispatch,
                 () => onClose(row._id));
    else
      onClose(row._id);
  };

  return (
    <Fragment>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={isOpen}
        onClose={() => onClose(null)}
        scroll="paper"
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <CloseBar onClose={() => onClose(null)} title={t("auto.confirm_output")}/>
        <DialogContent dividers>
          {!outputToNew ?
          <div style={{ margin: "20px" }}>
            <Typography style={{ margin: "20px 0 10px", color: "red" }}>
              <span style={{fontWeight: "bold"}}>{t("common.warning")}!</span> {t("auto.confirm_output_warning")}
            </Typography>
            <Typography style={{}}>
              {t("auto.confirm_output_proposal")}
            </Typography>
          </div> : null }
          <fieldset style={{
            marginTop: "10px",
            padding: "20px",
            borderRadius: 5,
            borderWidth: 1,
          }}>
            <legend style={{color: "#757575"}}>{t("auto.save_to")}</legend>
            <table>
              <tbody>
                <tr>
                  <td>
                    <FormControlLabel
                      control={<Radio/>}
                      label={t("auto.new_dataset")}
                      checked={outputToNew}
                      onChange={() => setOutputToNew(true)}
                    />
                  </td>
                  <td>
                    <TextField
                      required
                      size="small"
                      className={classes.dialogField}
                      onChange={event => setModelData({ new_name: event.target.value })}
                      variant="outlined"
                      value={modelData.new_name || ""}
                      placeholder={t('auto.new_dataset_name')}
                      fullWidth
                      disabled={!outputToNew}
                    />
                  </td>
                  <td>
                  </td>
                </tr>
                <tr>
                  <td>
                    <FormControlLabel
                      control={<Radio/>}
                      label={t("auto.existing_dataset")}
                      checked={!outputToNew}
                      onChange={() => setOutputToNew(false)}
                    />
                  </td>
                  <td>
                    <TextField
                      readOnly
                      key="out_dataset"
                      id="out_dataset"
                      size="small"
                      className={classes.dialogField}
                      variant="outlined"
                      value={datasets?.find(d => d._id == modelData.out_dataset)?.name || ""}
                      fullWidth
                    />
                    {0 && datasets ? <Autocomplete
                      readOnly
                      key="out_dataset"
                      id="out_dataset"
                      size="small"
                      options={datasets}
                      onChange={(event, value) => setModelData({ out_dataset: value?._id || null })}
                      getOptionLabel={(option) => option.name}
                      value={datasets?.find(d => d._id == modelData.out_dataset) || null}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label={t("auto.dataset")}/>
                      )}
                      style={{ marginTop: "20px" }}
                      disabled={outputToNew}
                    /> : null}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {!outputToNew ?
                      <span style={{ marginLeft: "50px", color: "red" }}>{t("common.warning")}! {t("auto.overwrite_warning")}</span> : null}
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(null)} color="primary" autoFocus>
            {t("common.cancel")}
          </Button>
          <Button onClick={saveAndStart} color="primary">
            {t("common.start")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

class AutoAnnotation extends React.Component{
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      showModal: null,
      modalContent: null,
      btnNameAgree: null,
      dialogTitle: null,
      editDialogOpen: false,
      confirmOutputDialogOpen: false,
      rowId: null,
      modelCloneName: "",
      showConfirmStatus: false,
      modalContentStatus: null,
    };

    this.runnable = new Runnable({
      getStatus: a => a?.status,
      statusName: 'Auto',
      updateStatus: this.getAutoList,
    });
  }

  setMessageErrorState = (obj) => {
    this.props.dispatch(setMessageState(obj));
    this.props.dispatch(setIsLoading(false));
  };

  getAutoList = () => {
    const { dispatch, projectId } = this.props;
    dispatch(fetchAutoModelList({ projectId }));
  };

  componentDidMount() {
    this.getAutoList();
  }

  componentDidUpdate() {
    this.runnable.componentDidUpdate(this.props.autoModelList?.automodels);
  }

  componentWillUnmount() {
    this.runnable.componentWillUnmount();

    this.abortController.abort();
  }

  abortController = new window.AbortController();

  handleEditRow = (id, name) => () => {
    this.setState({
      editDialogOpen: true,
      rowId: id,
    });
  };

  handleCloneRow = (id, name) => () => {
    const { t } = this.props;
    this.setState({
      rowId: id,
      modelCloneName: name,
      showModal: 'clone',
      dialogTitle: t('auto.clone'),
      modalContent: (
        <TextField
          defaultValue={name}
          onChange={(event, value) => { this.setState({ modelCloneName: event.target.value }) }}
          autoFocus
          size="small" style={{ margin: 8, paddingRight: 17 }}
          label={t('common.name')}
          variant="outlined"
          fullWidth
        />
      ),
      btnNameAgree: t('common.clone'),
    });
  };

  handleDeleteRow = (id, name) => () => {
    const { t } = this.props;
    this.setState({
      rowId: id,
      showModal: 'delete',
      dialogTitle: t('auto.delete'),
      modalContent: `${t("auto.delete_auto")}: "${name}"?`,
      btnNameAgree: null,
    });
  };

  handleClickNewRow = () => {
    this.setState({ editDialogOpen: true, rowId: null });
  };

  handleCloseEditDialog = (obj) => {
    this.setState({ editDialogOpen: false });
    const { dispatch, projectId, t } = this.props;
    const { rowId } = this.state;

    if (obj?.keyCode === 27 || !obj) return;

    (rowId ? putData(`/api/automodel/${rowId}`, { automodel: obj }) : postData(`/api/automodel`,
      { automodel: { ...obj, project: projectId } }))
      .then((data) => {
        dispatch(setIsLoading(false));
        if (data.error) {
          dispatch(setMessageState(
            { snackBarMessages: data.error.message, snackBarVariant: MESSAGE_STATUS.ERROR, snackBarState: true }));
        } else {
          dispatch(setMessageState(
            { snackBarMessages: t("auto.auto_saved"), snackBarVariant: MESSAGE_STATUS.SUCCESS, snackBarState: true }));
          this.getAutoList();
        }
      })
      .catch(error => {
        dispatch(setMessageState({
          snackBarMessages: "Auto Model Save error:" + error.message,
          snackBarVariant: MESSAGE_STATUS.ERROR,
          snackBarState: true,
        }));
        dispatch(setIsLoading(false));
      });
  };

  handleCloseConfirmOutputDialog = (id) => {
    this.setState({ confirmOutputDialogOpen: false});
    if (id) {
      this.getAutoList();
      this.handleStartRow(id);
    }
  };

  handleCloseModal = modalState => {
    const { showModal: action, modelCloneName, rowId } = this.state;
    const { dispatch, autoModelList } = this.props;
    this.setState({ showModal: null });
    if (modalState != DIALOG_USER_STATE.AGREE)
      return;
    if (action == 'clone') {
      const obj = { ...autoModelList.automodels.find(t => t._id === rowId) };
      if (obj) {
        ["createdAt", "status", "updatedAt", "_id"].forEach(attr => {
          delete obj[attr];
        });

        this.setState({ rowId: null }, () => {
          this.handleCloseEditDialog({ ...obj, name: modelCloneName });
        });
      }
    } else if (action == 'delete') {
      deleteDataNew(`/api/automodel/${rowId}`, dispatch, data => {
        this.getAutoList();
      });
    } else {
      CHK_FAIL(`unknown model action: ${action}`);
    }
  };

  simpleGet = (url, msg) => {
    const { dispatch } = this.props;

    getData(url)
      .then((data) => {
        dispatch(setIsLoading(false));
        if (data.error) {
          dispatch(setMessageState(
            { snackBarMessages: data.error.message, snackBarVariant: MESSAGE_STATUS.ERROR, snackBarState: true }));
        } else {
          dispatch(
            setMessageState({ snackBarMessages: msg, snackBarVariant: MESSAGE_STATUS.SUCCESS, snackBarState: true }));
          this.getAutoList();
        }
      })
      .catch(error => {
        dispatch(setMessageState({
          snackBarMessages: "Auto Model error:" + error.message,
          snackBarVariant: MESSAGE_STATUS.ERROR,
          snackBarState: true,
        }));
        dispatch(setIsLoading(false));
      });
  };

  handleStartRow = id => {
    this.simpleGet(`/api/automodel/${id}/start`, this.props.t("auto.auto_start"));
  };

  handleStopRow = id => {
    this.simpleGet(`/api/automodel/${id}/stop`, this.props.t("auto.auto_stop"));
  };

  openConfirmOutputDialog = id => {
    this.setState({ confirmOutputDialogOpen: true, rowId: id });
  };

  render() {
    const {
      showConfirmStatus, modalContentStatus, 
      editDialogOpen, confirmOutputDialogOpen, rowId,
      showModal, dialogTitle, modalContent, btnNameAgree,
    } = this.state;
    const { t, autoModelList = [], classes } = this.props;

    const headCells = [
      { _id: "name",    width: "15%", label: t("common.name"),  textSearch: true },
      { _id: "dataset", width: "20%", label: t("auto.dataset"),   filterOn: true },
      { _id: "model",   width: "20%", label: t("auto.model"),     filterOn: true },
      { _id: "date",    width: "20%", label: t("auto.date"),      dateTime: true },
      {
        _id: "status",  width: "10%", label: t("auto.status"), align: "left", link: (row) => {
          const modalContentStatus = <div>
            <p>{t("auto.annotation_status")}</p>
            {row.error && <p>{row.error}</p>}
            <p>{t("auto.annotation_start")} <span style={{ textDecoration: "underline" }}>{row.startTestAt}</span></p>
            <p>{t("auto.annotation_finish")} <span style={{ textDecoration: "underline" }}>{row.finishTestAt}</span></p>
          </div>;
          this.setState({ showConfirmStatus: true, modalContentStatus: modalContentStatus });
        },
        style: linkStyle(row => [row]),
      },
    ];

    return (
      <Fragment>
        {showModal && <ConfirmDialog
          title={dialogTitle}
          content={modalContent}
          closeModal={this.handleCloseModal}
          btnNameAgree={btnNameAgree}
        />}
        {showConfirmStatus &&
        <ConfirmDialog open={showConfirmStatus} content={modalContentStatus} showBtnNameAgree={false}
                       title={t("common.info")} btnNameDisagree={t("common.close")}
                       closeModal={() => this.setState({ showConfirmStatus: false })}/>}
        <EditDialog isOpen={editDialogOpen} automodels={autoModelList} classes={classes}
                    row={autoModelList?.automodels?.find(r => r._id === rowId)}
                    onClose={this.handleCloseEditDialog}/>
        <ConfirmOutputDialog
          isOpen={confirmOutputDialogOpen}
          onClose={this.handleCloseConfirmOutputDialog}
          automodels={autoModelList}
          row={autoModelList?.automodels?.find(r => r._id === rowId)}
          classes={classes}
        />
        <EnhancedTable
          id="auto"
          headCells={headCells}
          passedPage={true}
          rows={autoModelList?.automodels?.map(r => {
            return {
              ...r,
              dataset: autoModelList.datasets.find(d => d._id === r.dataset)?.name,
              model: autoModelList.models.find(m => m._id === r.model)?.name,
              date: r.status == 'Ready' && r.finishTestAt ? r.finishTestAt : r.updatedAt,
              finishTestAt: r.finishTestAt && new Date(r.finishTestAt).toLocaleString(),
              startTestAt: r.startTestAt && new Date(r.startTestAt).toLocaleString(),
              error: r.error?.json?.message || r.error?.error || r.error || '',
            };
          }) || []}
          viewCell={{ status: viewAutoStatus }}
          toolBarName={t("menu.auto_annotation")}
          newRowTitle={t("auto.add_auto")}
          handleClickNewRow={() => this.handleClickNewRow()}
          handleClickUpdateRow={() => {
            this.getAutoList();
          }}
          rowsOnPage={setDefaultRowsOnPage(0)}
          customBtns={(name, id) => {
            const isActive = this.runnable.isActive(autoModelList?.automodels, id);
            const ICONS = [
              ['edit',      this.handleEditRow,       EditIcon],
              ['clone',     this.handleCloneRow,      FileCopyIcon],
              ['delete',    this.handleDeleteRow,     DeleteIcon],
            ];
            return (
              <div style={{width: "25%", display: "flex"}}>
                <span>
                  <Button variant="outlined" size="small"
                    onClick={() => !isActive ? this.openConfirmOutputDialog(id, name) : this.handleStopRow(id, name)}
                  >
                    {t(!isActive ? "common.start" : "common.stop")}
                  </Button>
                </span>
                {ICONS.map(i => <IconButton key={i[0]} title={t("common."+ i[0])} onClick={i[1](id, name)} Icon={i[2]}/>)}
              </div>
            )}}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  projectId: state.settings.projectInfo.projectId,
  autoModelList: state.settings.autoModelList,
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(withTranslation()(AutoAnnotation))));
