import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import logger from 'redux-logger'

import settingsReducer from '../features/settings';
import messageInfoReducer from '../features/messageInfo';
import filtersReducer from '../features/filters';
import annotationSettingsReducer from '../features/annotationSettings';
import modelReducer from '../features/model';
import lookupReducer from '../features/lookup';
import modelFiltersReducer from '../features/modelFilters';

const loadState = () => {
	try {
		const state = localStorage.getItem('state');
		if (!state) {
			return {};
		}

		const deserializedState = JSON.parse(state);

		return deserializedState;
	} catch {
		return {};
	}
}

const saveState = (state) => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem('state', serializedState);
	} catch {
		// Ignore serialize errors
	}
};

const store = {
	reducer: {
		settings: settingsReducer,
		messageInfo: messageInfoReducer,
		filters: filtersReducer,
		annotationSettings: annotationSettingsReducer,
		model: modelReducer,
		lookup: lookupReducer,
		modelFilters: modelFiltersReducer,
	},
	preloadedState: loadState(),
}

if (process.env.NODE_ENV === `development`) {
  store.middleware = [...getDefaultMiddleware({
    immutableCheck: { warnAfter: 500 },
    serializableCheck: { warnAfter: 500 },
  }) /*, logger */];
}

const s = configureStore(store);

s.subscribe(() => {
	const settings = s.getState().settings;

	saveState({
		settings: {
			projectInfo: settings.projectInfo,
			selectedDataSetId: settings.selectedDataSetId,
		}
	});
});

export default s;
