import React from "react";
import { connect } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import TableCell from "@material-ui/core/TableCell";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import Dialog from "@material-ui/core/Dialog";
import CloseBar from "../../components//dialogCloseBar";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from "@material-ui/core/DialogContent";
import MultiSelect from "../../components/material-ui/MultiSelect";
import NumberField from "../../components/material-ui/NumberField";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import {
  setProjects,
  setProjectDataSet,
} from "../../../features/settings";
import {
  getDataNew,
  postDataNew,
  putDataNew,
} from "../../../core/fetchService";
import { NLU_TYPE_AC_OPTS } from "../../../core/constants";
import ConfirmDialog from "../../components/confirmDialog";
import { withTranslation } from "react-i18next";
import { IsAdmin, cmpPossibleNumbers, getRunConfigOption } from "../../../core/utils";

import notBackdropClicked from "../../components/helpers/notBackdropClicked";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    flexWrap: "wrap",
  },
  paper: {
    padding: "18px",
    textAlign: "left",
  },
  textField: {
    marginTop: 8
  },
  column: {
    flexBasis: "33.33%",
  },
  heading: {
    fontSize: "12",
  },
  gridList: {
    overflow: "hidden",
    padding: 5
  },
  summary: {
    marginLeft: "20px",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  tdTopCell: {
    borderTop: "1px solid #000",
  },
  typography: {
    fontSize: 20
  },
  button: {
    marginTop: 10,
    marginLeft: 10
  }
});

const EMPTY_SIM_CONFIG_DETAILS = { models: {} };

class EditProject extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      showModal: false,
      modalContent: "",
      loading: true,
      openList: {
        nlu: false,
        asr: false,
        similarity: false,
        import: false,
      },
      similarityConfigDetails: EMPTY_SIM_CONFIG_DETAILS,
      savedValue: null,
    };
  };

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      this.props.open?
        this.props.id?
          this.getProjectData():
          this.setState({
            loading: false
          }):
          this.setState({
            project: null,
            loading: true
          })
    }
  };

  user_groups = [];

  getProjectData = async (scd = true) => {
    if (IsAdmin())
      await getDataNew(`/api/user_group`, this.props.dispatch, data => { this.user_groups = data.user_groups });

    const url = `/api/project/${this.props.id}?${scd ? 'similarity_config_details=true' : ''}`;
    const res = await getDataNew(url, this.props.dispatch, data => {
      const getConfigValue = (n,k) => data.project[n] && data.project[n][k] || "";
      const getNLUConfig = k => getConfigValue('nluConfig', k);
      const getASRConfig = k => getConfigValue('asrConfig', k);
      const getComposerConfig = k => getConfigValue('composerConfig', k);
      const getSimilarityConfig = k => getConfigValue('similarityConfig', k);
      const access = (IsAdmin() && data.project.access || [])
        .map(i => this.user_groups.find(g => g._id == i) || { name: `--Not-Found-!--(${i})` });

      const newProject = {
          ...data.project,
          access,
          nlu_train_url:                  getNLUConfig('train_url'),
          nlu_deploy_url:                 getNLUConfig('deploy_url'),
          nlu_model_server_url:           getNLUConfig('model_server_url'),
          asr_train_url:                  getASRConfig('train_url'),
          asr_deploy_url:                 getASRConfig('deploy_url'),
          asr_model_server_url:           getASRConfig('model_server_url'),
          composerUrl:                    getComposerConfig('url'),
          composerApplication:            getComposerConfig('applicationId'),
          composerToken:                  getComposerConfig('token'),
          similarityConfigServer:         getSimilarityConfig('server'),
          similarityConfigMax:            getSimilarityConfig('max'),
          similarityConfigModel:          getSimilarityConfig('model'),
          similarityConfigThreshold:      getSimilarityConfig('threshold'),
          similarityConfigPartition:      getSimilarityConfig('partition') || null,
      }

      this.setState({project: newProject, similarityConfigDetails: data.similarity_config_details || this.state.similarityConfigDetails});
      if (data.project.datasets)
        this.props.dispatch(setProjectDataSet(data.project.datasets));

      if (newProject.nlu_train_url || newProject.nlu_deploy_url || newProject.nlu_model_server_url) 
        this.setState({openList: {...this.state.openList, nlu: true}});
      if (newProject.asr_train_url || newProject.asr_deploy_url || newProject.asr_model_server_url) 
        this.setState({openList: {...this.state.openList, asr: true}});
      if (newProject.similarityConfigServer || newProject.similarityConfigMax || newProject.similarityConfigModel || newProject.similarityConfigThreshold || newProject.similarityConfigPartition) 
        this.setState({openList: {...this.state.openList, similarity: true}});
      if (newProject.composerUrl || newProject.composerApplication || newProject.composerToken) 
        this.setState({openList: {...this.state.openList, import: true}});
    })
    .then(() => this.setState({loading: false}));

    /* retry w/o similarity_config_details in case of error */
    res?.error && scd && this.getProjectData(false);
  };

  handleSetProjectData = (name, is_autocomplete) => (event, new_value) => {
    const value = is_autocomplete ? new_value : event.target.value;
    const other = name == 'similarityConfigModel' ? { similarityConfigPartition: null }
      : name == 'similarityConfigServer' ? { similarityConfigModel: null, similarityConfigPartition: null } : {};
    const other2 = name == 'similarityConfigServer' ? { similarityConfigDetails: EMPTY_SIM_CONFIG_DETAILS } : {};
    this.setState({ project: { ...this.state.project, [name]: value, ...other }, ...other2 });
  };

  handleSimilarityConfigServerFocus = event => { this.setState({ savedValue: event.target.value }) };
  handleSimilarityConfigServerChange = async event => {
    const value = event.target.value;
    if (!value || value == this.state.savedValue)
      return;
    const project = this.state.project_id ? 'project='+ this.state.project.id +'&' : '';
    const url = `/api/project/similarity_config_details?${project}server=${value}`;
    let similarityConfigDetails = EMPTY_SIM_CONFIG_DETAILS;
    await getDataNew(url, this.props.dispatch, data => {
      if (data.similarity_config_details)
        similarityConfigDetails = data.similarity_config_details;
    });
    // in case of request error this resets similarityConfigDetails
    this.setState({ similarityConfigDetails });
  };

  handleUpdateProject = () => {
    const { projects } = this.props;
    const {
      id,
      name,
      description,
      access,
      nlu_train_url,
      nlu_deploy_url,
      nlu_model_server_url,
      asr_train_url,
      asr_deploy_url,
      asr_model_server_url,
      similarityConfigServer,
      similarityConfigModel,
      similarityConfigMax,
      similarityConfigThreshold,
      similarityConfigPartition,
      composerUrl,
      composerApplication,
      composerToken,
    } = this.state.project;

    const project = {
      name,
      description,
      access: access?.map(g => g._id),
    };

    project.nluConfig = {
      train_url: nlu_train_url,
      deploy_url: nlu_deploy_url,
      model_server_url: nlu_model_server_url,
    };
    project.asrConfig = {
      train_url: asr_train_url,
      deploy_url: asr_deploy_url,
      model_server_url: asr_model_server_url,
    };
    project.similarityConfig = {
      server: similarityConfigServer,
      ...(similarityConfigServer && similarityConfigModel ? {
        model: similarityConfigModel,
        ...(similarityConfigPartition ? {
          partition: similarityConfigPartition,
        } : {}),
      } : {}),
      max:        similarityConfigMax,
      threshold:  similarityConfigThreshold,
    };
    project.composerConfig = {
      url: String(composerUrl || '').trim(),
      token: String(composerToken || '').trim(),
      applicationId: String(composerApplication || '').trim(),
    };

    (id? putDataNew: postDataNew)(`/api/project/${id || ''}`, {
      project,
    }, () => {
      this.props.dispatch(setProjects(projects.map(p => p._id == id ? {...p, ...project} : p)));
    }, this.props.dispatch)
    .then(() => this.props.handleCloseEdit())
    .then(() => this.props.getAllProjects())
  };
  
  showStatisticsDialog = () => {
    const { classes, t, id } = this.props;

    getDataNew(`/api/project/${id}/stats`, this.props.dispatch, data => {
      this.setState({
        showModal: true,
        modalContent: (
          <div className={classes.summary}>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <GridList className={classes.gridList} cols={2}>
                  {[
                    t("projects.intent"),
                      "intentCount",
                      t("projects.slots"),
                      "slotCount",
                      t("projects.entities"),
                      "entityCount",
                  ].map((v, idx) => (
                    <GridListTile style={{ height: "auto" }} key={idx}>
                      <span>
                        {(() => {
                          if (idx%2 <= 0) return v;
                          return data[v] || 0;
                        })()}
                      </span>
                    </GridListTile>
                  ))}
                </GridList>
              </Grid>
              <Grid item xs={7}>
                <TableContainer>
                  <Table
                    stickyHeader
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">
                          {t("projects.entries")}
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                          {t("projects.annotated")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center">
                          {t("projects.manual")}
                        </TableCell>
                        <TableCell align="center">
                          {t("projects.auto")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.datasets.map((ds, idx) => (
                        <TableRow key={idx}>
                          <TableCell component="th" scope="row">
                            {ds.name}
                          </TableCell>
                          <TableCell align="center">
                            {ds.rowCount || 0}
                          </TableCell>
                          {/* place manual */}
                          <TableCell align="center">
                            {ds.rowManualCount || 0}
                          </TableCell>
                          {/* place auto */}
                          <TableCell align="center">
                            {ds.rowAutoCount || 0}
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tdTopCell}
                        >
                          {t("common.all")}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.tdTopCell}
                        >
                          {data?.rowCount}
                        </TableCell>
                        {/* place sum by manual */}
                        <TableCell
                          align="center"
                          className={classes.tdTopCell}
                        >
                          {data?.rowManualCount}
                        </TableCell>
                        {/* place sum by auto */}
                        <TableCell
                          align="center"
                          className={classes.tdTopCell}
                        >
                          {data?.rowAutoCount}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        ),
      });
    });
  };

  handleOpenSettingList = itemName => {
    this.setState({ openList: { ...this.state.openList, [itemName]: !this.state.openList[itemName] }});
  };

  onCancel = () => {
    this.setState({
      openList: {
        nlu: false,
        asr: false,
        similarity: false,
        import: false,
      },
      project: null,
      loading: true
    });
    this.props.handleCloseEdit()
  }

  render() {
    const { classes, t } = this.props;
    const { project, similarityConfigDetails, showModal, modalContent } = this.state;

    return (
        !this.state.loading && 
          <Dialog
            onClose={notBackdropClicked(() => this.onCancel())}
            open={this.props.open}
            fullWidth
            maxWidth='md'
          >
          <CloseBar onClose={this.onCancel} title={this.props.id? t('projects.edit_project'): t('projects.add_new_project')}/>
            <DialogContent>
              <div className={classes.root}>
                {showModal && (
                  <ConfirmDialog
                    maxWidth="md"
                    open={true}
                    btnNameDisagree={t("common.close")}
                    showBtnNameAgree={false}
                    title={t("train.summary")}
                    content={modalContent}
                    closeModal={() => { this.setState({ showModal: false }) }}
                  />)}
                <Grid
                  container
                  direction="column"
                  justify="space-evenly"
                  alignItems="stretch"
                  spacing={3}
                >
                  <Grid item>
                    <Paper className={classes.paper}>
                      <div style={{ paddingRight: 8 }}>
                        <TextField
                          required
                          size="small"
                          id="projectName"
                          label={t("common.name")}
                          defaultValue={project?.name}
                          variant="outlined"
                          className={classes.textField}
                          onChange={this.handleSetProjectData("name")}
                        />
                        <Button
                          className={classes.button}
                          disabled={!this.props.id}
                          variant="contained"
                          color="primary"
                          onClick={() => this.showStatisticsDialog()}
                        >
                          {t("common.statistics")}
                        </Button>
                        <TextField
                          required
                          size="small"
                          fullWidth
                          id="projectDescription"
                          label={t("common.description")}
                          multiline
                          rowsMax="4"
                          className={classes.textField}
                          defaultValue={project?.description}
                          variant="outlined"
                          onChange={this.handleSetProjectData("description")}
                        />
                        {IsAdmin() && getRunConfigOption('enableRoleModel') ? <MultiSelect
                          id="access"
                          className={classes.textField}
                          defaultValue={project?.access}
                          onChange={this.handleSetProjectData("access", true)}
                          options={this.user_groups}
                          getOptionLabel={option => option.name}
                          label={t("projects.access")}
                        /> : null}
                      </div>
                      <div className={classes.summary} style={{ marginLeft: 8 }}>
                        <List component="nav">
                          <ListItem button onClick={() => this.handleOpenSettingList("nlu")}>
                            <ListItemText primary={t("projects.default_nlu_settings")}/>
                            {this.state.openList.nlu ? <ExpandLess/> : <ExpandMore/>}
                          </ListItem>
                          <Collapse in={this.state.openList.nlu} timeout="auto">
                            <GridList className={classes.gridList} cols={1}>
                              <GridListTile cols={1} style={{ height: "auto" }}>
                                <TextField
                                  size="small"
                                  className={classes.textField}
                                  label={t("projects.nlu_train_test")}
                                  variant="outlined"
                                  fullWidth
                                  defaultValue={project?.nlu_train_url}
                                  onChange={this.handleSetProjectData("nlu_train_url")}
                                />
                              </GridListTile>
                              <GridListTile cols={1} style={{ height: "auto" }}>
                                <TextField
                                  size="small"
                                  className={classes.textField}
                                  label={t("projects.nlu_deploy_url")}
                                  variant="outlined"
                                  fullWidth
                                  defaultValue={project?.nlu_deploy_url}
                                  onChange={this.handleSetProjectData("nlu_deploy_url")}
                                />
                              </GridListTile>
                              <GridListTile cols={1} style={{ height: "auto" }}>
                                <TextField
                                  size="small"
                                  id="modelServerURL"
                                  label={t("projects.nlu_model_server_url")}
                                  defaultValue={project?.nlu_model_server_url}
                                  variant="outlined"
                                  fullWidth
                                  className={classes.textField}
                                  onChange={this.handleSetProjectData("nlu_model_server_url")}
                                />
                              </GridListTile>
                            </GridList>
                          </Collapse>
                          <ListItem button onClick={() => this.handleOpenSettingList("similarity")}>
                            <ListItemText primary={t("projects.default_similarity_settings")}/>
                            {this.state.openList.similarity ? <ExpandLess/> : <ExpandMore/>}
                          </ListItem>
                          <Collapse in={this.state.openList.similarity} timeout="auto">
                            <GridList className={classes.gridList} cols={1}>
                                <GridListTile cols={1} style={{ height: "auto" }}>
                                  <TextField
                                    size="small"
                                    className={classes.textField}
                                    variant="outlined"
                                    label={t("projects.similarity_server")}
                                    fullWidth
                                    defaultValue={project?.similarityConfigServer || ""}
                                    placeholder="http://example.org:0000"
                                    onFocus={this.handleSimilarityConfigServerFocus}
                                    onChange={this.handleSetProjectData("similarityConfigServer")}
                                    onBlur={this.handleSimilarityConfigServerChange}
                                  />
                                </GridListTile>
                                <GridListTile cols={1} style={{ height: "auto" }}>
                                  <Autocomplete
                                    size="small"
                                    fullWidth
                                    className={classes.textField}
                                    options={Object.keys(similarityConfigDetails.models)}
                                    getOptionLabel={m => {
                                      const cps = similarityConfigDetails.models[m]?.cluster_partitions;
                                      return cps && cps.length ? m +` _ _ _ _ _ [${t("projects.cluster_partitions")}: `
                                        + cps.join(', ') +']' : m;
                                    }}
                                    onChange={this.handleSetProjectData("similarityConfigModel", true)}
                                    defaultValue={project?.similarityConfigModel}
                                    renderInput={params =>
                                      <TextField {...params} variant="outlined" label={t("projects.model")}/>
                                    }
                                  />
                                </GridListTile>
                                <GridListTile cols={1} style={{ height: "auto" }}>
                                  <Autocomplete
                                    size="small"
                                    fullWidth
                                    options={
                                      (project?.similarityConfigModel
                                        && similarityConfigDetails.models[project?.similarityConfigModel]?.cluster_partitions || [])
                                        .sort(cmpPossibleNumbers)
                                    }
                                    className={classes.textField}
                                    defaultValue={project?.similarityConfigPartition ? String(project.similarityConfigPartition) : null}
                                    renderInput={params =>
                                      <TextField
                                        {...params} variant="outlined" label={t("projects.cluster_partitions")}
                                      />}
                                    onChange={this.handleSetProjectData("similarityConfigPartition", true)}
                                  />
                                </GridListTile>
                              <GridListTile cols={1} style={{ height: "auto" }}>
                                <NumberField
                                  label={t("projects.max_number")}
                                  className={classes.textField}
                                  value={project?.similarityConfigMax || ''}
                                  inputProps={{ min: 0 }}
                                  onChange={this.handleSetProjectData("similarityConfigMax")}
                                />
                              </GridListTile>
                              <GridListTile cols={1} style={{ height: "auto" }}>
                                <NumberField
                                  label={t("projects.sim_threshold")}
                                  className={classes.textField}
                                  value={project?.similarityConfigThreshold || ''}
                                  inputProps={{ min: 0, max: 1, step: '.1' }}
                                  onChange={this.handleSetProjectData("similarityConfigThreshold")}
                                />
                              </GridListTile>
                            </GridList>
                          </Collapse>
                          <ListItem button onClick={() => this.handleOpenSettingList("asr")}>
                            <ListItemText primary={t("projects.default_asr_settings")}/>
                            {this.state.openList.asr ? <ExpandLess/> : <ExpandMore/>}
                          </ListItem>
                          <Collapse in={this.state.openList.asr} timeout="auto">
                            <GridList className={classes.gridList} cols={1}>
                              <GridListTile cols={1} style={{ height: "auto" }}>
                                <TextField
                                  size="small"
                                  className={classes.textField}
                                  label={t("projects.asr_train_test")}
                                  variant="outlined"
                                  fullWidth
                                  defaultValue={project?.asr_train_url}
                                  onChange={this.handleSetProjectData("asr_train_url")}
                                />
                              </GridListTile>
                              <GridListTile cols={1} style={{ height: "auto" }}>
                                <TextField
                                  size="small"
                                  className={classes.textField}
                                  label={t("projects.asr_deploy_url")}
                                  variant="outlined"
                                  fullWidth
                                  defaultValue={project?.asr_deploy_url}
                                  onChange={this.handleSetProjectData("asr_deploy_url")}
                                />
                              </GridListTile>
                              <GridListTile cols={1} style={{ height: "auto" }}>
                                <TextField
                                  size="small"
                                  id="modelServerURL"
                                  label={t("projects.asr_model_server_url")}
                                  defaultValue={project?.asr_model_server_url}
                                  variant="outlined"
                                  fullWidth
                                  className={classes.textField}
                                  onChange={this.handleSetProjectData("asr_model_server_url")}
                                />
                              </GridListTile>
                            </GridList>
                          </Collapse>
                          <ListItem style={{ marginBottom: 8 }} button onClick={() => this.handleOpenSettingList("import")}>
                            <ListItemText primary={t("projects.default_import_settings")}/>
                            {this.state.openList.import ? <ExpandLess/> : <ExpandMore/>}
                          </ListItem>
                          <Collapse in={this.state.openList.import} timeout="auto">
                            <GridList className={classes.gridList} cols={1} style={{ padding: 5 }}>
                              <GridListTile cols={1} style={{ height: "auto" }}>
                                <TextField
                                  className={classes.textField}
                                  size="small"
                                  label={t("projects.composer_url")}
                                  variant="outlined"
                                  fullWidth
                                  defaultValue={project?.composerUrl}
                                  onChange={this.handleSetProjectData("composerUrl")}
                                />
                              </GridListTile>
                              <GridListTile cols={1} style={{ height: "auto" }}>
                                <TextField
                                  size="small"
                                  className={classes.textField}
                                  label={t("projects.composer_app_id")}
                                  variant="outlined"
                                  fullWidth
                                  defaultValue={project?.composerApplication}
                                  onChange={this.handleSetProjectData("composerApplication")}
                                />
                              </GridListTile>
                              <GridListTile cols={1} style={{ height: "auto" }}>
                                <TextField
                                  size="small"
                                  className={classes.textField}
                                  label={t("projects.composer_token")}
                                  variant="outlined"
                                  fullWidth
                                  multiline
                                  defaultValue={project?.composerToken}
                                  onChange={this.handleSetProjectData("composerToken")}
                                />
                              </GridListTile>
                            </GridList>
                          </Collapse>
                        </List>
                      </div>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </DialogContent>
            <DialogActions>
              <Button disabled={!project?.name || !project?.description} onClick={() => this.handleUpdateProject()}>
                {t("common.save")}
              </Button>
              <Button onClick={this.onCancel}>
                {t("common.cancel")}
              </Button>
            </DialogActions>
        </Dialog>
    )
  }
}

const mapStateToProps = (state) => ({
  projects: state.settings.projects
});

export default connect(mapStateToProps)(
  withStyles(styles)(withTranslation()(EditProject)),
);
