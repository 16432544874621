import { createSlice } from '@reduxjs/toolkit';
import { getData } from '../core/fetchService'
import { setMessageState } from './messageInfo'
import { setIsLoading } from '../features/settings';
import { MESSAGE_STATUS } from '../core/constants';

export const lookup = createSlice({
  name: 'lookup',
  initialState: {
    entityGroup: null,
    scopes: null, 
    entities: null
  },
  reducers: {
    setEntityGroup: (state, action) => {
        state.entityGroup = action.payload;
    },
    setEntities: (state, action) => {
        state.entities = action.payload;
    },
    setScopes: (state, action) => {
        state.scopes = action.payload;
    },
  },
});

export const { setEntityGroup, setEntities, setScopes } = lookup.actions;

export const getEntityGroup = (state) => state.lookup.entityGroup;
export const getEntities = (state) => state.lookup.entities;
export const getScopes = (state) => state.lookup.scopes;

export default lookup.reducer;

export const fetchAllEntities = (obj) => (dispatch) => {
	const { projectId } = obj;
    dispatch(setIsLoading(true));
	getData(`/api/lookup?project=${projectId}`)
		.then((data) => {
			if (data.error) {
				dispatch(setMessageState({ snackBarMessages: data.error.message, snackBarVariant: MESSAGE_STATUS.ERROR, snackBarState: true }));
			}
			else {
				let entityGroup = data.lookups?.filter(l => l.scope !== 'system').reduce((r, a) => {
                    r[a.scope] = [...r[a.scope] || [], a];
                    return r;
                }, {});

                dispatch(setEntityGroup(entityGroup));

                let scopes = entityGroup && Object.keys(entityGroup).map(k => k);

                dispatch(setScopes(scopes));
                dispatch(setEntities(data.lookups));
			}
			dispatch(setIsLoading(false));
		})
		.catch(error => {
			dispatch(setMessageState({ snackBarMessages: error.message, snackBarVariant: MESSAGE_STATUS.ERROR, snackBarState: true }));
			dispatch(setIsLoading(false));
		});
}