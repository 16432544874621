import React, { Fragment, useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import IconButton from "../../components/material-ui/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";
import { withRouter } from "react-router";
import { withTranslation, useTranslation } from "react-i18next";
import { Grid, ListItemSecondaryAction } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import DeleteIcon from "@material-ui/icons/Delete";
import Highlighter from "react-highlight-words";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import { download } from "../../../core/utils";
import { fetchIntents } from "../../../features/annotationSettings";
import { fetchModelFilters, modelFilters } from "../../../features/modelFilters";
import { DIALOG_USER_STATE, MESSAGE_STATUS } from "../../../core/constants";
import {
  putDataNew,
  deleteData,
  uploadFile,
  postDataNew,
} from "../../../core/fetchService";
import { setMessageState } from "../../../features/messageInfo";
import { setIsLoading, fetchAllSlots } from "../../../features/settings";

import AddEditDialog from "./AddEditDialog";
import ConfirmDialog from "../../components/confirmDialog";
import Filter from "./Filter"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  title: {
    flex: "1 1 100%",
    textAlign: "left",
  },
  list: {
    minHeight: "384px",
    minWidth: "200px",
    marginTop: "34px",
    borderRadius: "4px",
    padding: 0,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
  },
  filter: {
    flexGrow: 1,
    width: "100%",
  },
  filterOutline: {
    borderWidth: "0 !important",
  },
  tabs: {
    width: "100%",
  },
}));

const TabPanel = (props) => {
  const { children, value, index, visible, ...other } = props;
  return (
    <div
      {...other}
      role="tabpanel"
      hidden={value !== index || !visible}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const Filters = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();

  const [tabValue, setTabValue] = useState(0);
  const [addEditDialogState, setAddEditDialogState] = useState({open: false, filter: null});
  const [searchFilter, setSearchFilter] = useState("");
  const [selectedFilter, setSelectedFilter] = useState();
  const [deleteFilterDialogState, setDeleteFilterDialogState] = useState({
    isOpen: false,
    content: "",
    filterId: "",
  });

  useEffect(() => {
    setSelectedFilter();
    setTabValue(0);
    dispatch(fetchModelFilters(props.projectId));
    dispatch(fetchIntents({ projectId: props.projectId }));
    dispatch(fetchAllSlots({ projectId: props.projectId }));
  }, [props.projectId]);

  useEffect(() => {
    if (Array.isArray(props.filters) && props.filters.length) {
      setSelectedFilter(selectedFilter || props.filters[0]);
    }
  }, [props.filters]);

  const deleteFilter = async (filterId) => {
    dispatch(setIsLoading(true));

    try {
      const response = await deleteData(`/api/filter/${filterId}`);

      if (response.error) {
        dispatch(
          setMessageState({
            snackBarMessages: response.error.message,
            snackBarVariant: MESSAGE_STATUS.ERROR,
            snackBarState: true,
          }),
        );
      }
    } catch (error) {
      dispatch(
        setMessageState({
          snackBarMessages: error.message,
          snackBarVariant: MESSAGE_STATUS.ERROR,
          snackBarState: true,
        }),
      );
    }

    dispatch(fetchModelFilters(props.projectId));
    dispatch(setIsLoading(false));
  };

  const importFilter = async (filter, formData) => {
    const { _id, name } = filter;

    dispatch(setIsLoading(true));

    try {
      const response = await uploadFile(`/api/filter/${_id}/import`, formData);

      if (response.error) {
        dispatch(
          setMessageState({
            snackBarMessages: response.error.message,
            snackBarVariant: MESSAGE_STATUS.ERROR,
            snackBarState: true,
          }),
        );
      }

      setSelectedFilter(response);
    } catch (error) {
      dispatch(
        setMessageState({
          snackBarMessages: error.message,
          snackBarVariant: MESSAGE_STATUS.ERROR,
          snackBarState: true,
        }),
      );
    }

    dispatch(fetchModelFilters(props.projectId));
    dispatch(setIsLoading(false));
  };

  const exportFilter = async () =>
    download(`/api/filter/${selectedFilter._id}/export`, dispatch);

  const toggleAddEditDialog = (open, filter) => {
    setAddEditDialogState({open, filter});
  };

  const handleTabChange = (event, newTabValue) => {
    setTabValue(newTabValue);
  };

  const handleDownload = async () => {
    await exportFilter();
  };

  const handleFilterChange = (filterId) => {
    setSelectedFilter(props.filters.find(({ _id }) => _id === filterId));
  };

  const handleFilterSearch = (event) => {
    setSearchFilter(event.target.value);
  };

  const handleCleanUpFilterSearch = () => {
    setSearchFilter("");
  };

  const handleDeleteDialogOpen = (filterId, filterName) => {
    setDeleteFilterDialogState({
      filterId,
      isOpen: true,
      content: `${props.t("filters.delete_question")}: "${filterName}"?`,
    });
  };

  const handleDeleteDialogClose = async (result) => {
    if (result === DIALOG_USER_STATE.AGREE) {
      await deleteFilter(deleteFilterDialogState.filterId);

      if (!modelFilters.length) {
        setSelectedFilter();
      } else if (
        selectedFilter &&
        selectedFilter._id === deleteFilterDialogState.filterId
      ) {
        setSelectedFilter(modelFilters[0]);
      }
    }

    setDeleteFilterDialogState({
      isOpen: false,
      content: undefined,
      filterId: undefined,
    });
  };

  const handleAddEditDialogAccept = async ({ name, file }) => {
    const { projectId, dispatch } = props;
    
    const asyncFunc = !addEditDialogState.filter? postDataNew: putDataNew;
    const filter = !addEditDialogState.filter? { project: projectId, name }: {...addEditDialogState.filter, name};

    const result = await asyncFunc(`/api/filter/${addEditDialogState.filter?._id || ''}`, { filter }, dispatch);

    if (file && !result.error) await importFilter(result.filter, file.form);
    if (!result.error) await dispatch(fetchModelFilters(projectId));

    
    setSelectedFilter(result.filter);
    toggleAddEditDialog(false);
  };

  return (
    <div className={classes.root}>
      <AddEditDialog
        filterName={addEditDialogState.filter?.name}
        isOpen={addEditDialogState.open}
        onAccept={handleAddEditDialogAccept}
        onReject={() => toggleAddEditDialog(false)}
      />
      <ConfirmDialog
        title={t("filters.delete_filter")}
        open={deleteFilterDialogState.isOpen}
        content={deleteFilterDialogState.content}
        closeModal={handleDeleteDialogClose}
      />
      <Grid container
            wrap="nowrap"
            style={{ overflow: "auto" }}>
        <Grid item
              xs={4}
              sm={4}
              style={{ padding: "10px", minHeight: "650px", minWidth: "200px" }}
        >
          <Toolbar variant="dense">
            <Typography
              component={"span"}
              className={classes.title}
              variant="h6"
              id="tableTitle"
            >
              {t("menu.filters")}
            </Typography>
            <IconButton disabled={!selectedFilter} title={t("filters.export_filter")} onClick={handleDownload} Icon={GetAppIcon}/>           
            <IconButton title={t("filters.add_filter")} onClick={() => toggleAddEditDialog(true)} Icon={AddBoxIcon}/>
          </Toolbar>
          <List dense key="filters" className={classes.list}>
            <TextField
              className={classes.filter}
              placeholder={t("common.search")}
              variant={"outlined"}
              size={"small"}
              onInput={handleFilterSearch}
              value={searchFilter}
              InputProps={{
                classes: {
                  notchedOutline: classes.filterOutline,
                  focused: classes.filterOutline,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small"/>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">           
                    <IconButton disabled={!searchFilter} title={t("common.clear_search")} onClick={() => handleCleanUpFilterSearch()} Icon={ClearIcon}/>
                  </InputAdornment>
                ),
              }}
            />
            {(props.filters || [])
              .filter(({ name }) =>
                name
                  .toLocaleLowerCase()
                  .includes(searchFilter.toLocaleLowerCase()),
              )
              .map(({ _id, name }) => {
                return (
                  <ListItem
                    button
                    key={_id}
                    selected={selectedFilter?._id === _id}
                    onClick={() => handleFilterChange(_id)}
                  >
                    <Tooltip title={<h3>{name}</h3>}>
                      <ListItemText
                        primaryTypographyProps={{
                          style: {
                            width: "70%",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          },
                        }}
                        primary={
                          <Highlighter
                            searchWords={[searchFilter]}
                            autoEscape={true}
                            textToHighlight={name}
                          />
                        }
                      />
                    </Tooltip>
                    <ListItemSecondaryAction>
                      <IconButton title={t("filters.edit_filter")} onClick={() => toggleAddEditDialog(true, {_id, name})} Icon={EditIcon}/>
                      <IconButton title={t("filters.delete_filter")} onClick={() => handleDeleteDialogOpen(_id, name)} Icon={DeleteIcon}/>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
          </List>
        </Grid>
        <Grid item xs={8} sm={8}>
          <Toolbar disableGutters>
            <Tabs
              centered
              className={classes.tabs}
              value={tabValue}
              onChange={handleTabChange}
            >
              <Tab
                label={t("filters.exclude_intents_tab_title")}
                disabled={!selectedFilter}
                {...a11yProps(0)}
              />
              <Tab
                label={t("filters.replace_intents_tab_title")}
                disabled={!selectedFilter}
                {...a11yProps(1)}
              />
              <Tab
                label={t("filters.exclude_slots_tab_title")}
                disabled={!selectedFilter}
                {...a11yProps(2)}
              />
              <Tab
                label={t("filters.replace_slots_tab_title")}
                disabled={!selectedFilter}
                {...a11yProps(3)}
              />
            </Tabs>
          </Toolbar>
          {['exclude_intent', 'replace_intent', 'exclude_slot', 'replace_slot'].map((value, index) => {
            const isIntent = value.includes('intent');
            const isExclude = value.includes('exclude');
            
            const type = `${isExclude && 'exclude' || 'replace'}${isIntent && 'Intents' || 'Slots'}`;

            const options = isIntent? 
                props.intents?.map((intent) => ({
                  value: intent._id,
                  label: intent.name,
                })):
              props.slots?.map((slot) => ({
                value: slot._id,
                label: slot.name,
              }));

              return <TabPanel visible={selectedFilter} value={tabValue} index={index} key={index}>
                        <Filter
                          type={type}
                          setSelectedFilter={setSelectedFilter}
                          options={options || []}
                          addButtonTooltip={t(`filters.${value}`)}
                          selectedFilter={selectedFilter}
                          entitySelectPlaceholder={isIntent? t(`filters.select_intent`): t(`filters.select_slot`)}
                          filterValues={selectedFilter?.[type] || (isExclude && [] || {})}
                          projectId={props.projectId}
                        />
                      </TabPanel>
              })}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  projectId: state.settings.projectInfo.projectId,
  intents: state.annotationSettings.intentsList,
  slots: state.settings.slots,
  filters: state.modelFilters.filters,
  currentFilter: state.modelFilters.currentFilter,
});

export default withRouter(connect(mapStateToProps)(withTranslation()(Filters)));
