import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { setIsLoading, fetchAllSlots } from '../../../features/settings';
import { setMessageState } from '../../../features/messageInfo';
import { getData, postDataNew, deleteData, putData, uploadFile } from '../../../core/fetchService'
import { MESSAGE_STATUS, DIALOG_USER_STATE } from '../../../core/constants';
import EnhancedTable from '../../components/projectTable';
import IconButton from '../../components/material-ui/IconButton';
import iconList from '../../components/helpers/iconList';
import DeleteIcon from '@material-ui/icons/Delete';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import EditIcon from '@material-ui/icons/Edit';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ConfirmDialog from '../../components//confirmDialog'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Tooltip from '@material-ui/core/Tooltip';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import Upload from '../../components/fieldUpload';
import { setDefaultRowsOnPage, download } from '../../../core/utils';
import { withTranslation, useTranslation } from 'react-i18next';
import CloseBar from "../../components/dialogCloseBar";
import notBackdropClicked from "../../components/helpers/notBackdropClicked";

function EditDialog(props) {
	const { isOpen, assotiatedEntitiesList, onClose, slot } = props;
	const [name, setName] = useState("");
	const [assotiatedEntity, setAssotiatedEntity] = useState([]); //label:"system.color" name:"color" scope:"system" value:"5e74e3f6e30cd115fb69ca25"
	const { t } = useTranslation();

	useEffect(() => {
		setName((slot && slot.name) || "");
		setAssotiatedEntity(slot?.entityIds?.map(entityId => assotiatedEntitiesList.find(a => a.value === entityId)) || []);
	}, [slot]);

	const handleClose = (isOpen) => {
		if (!isOpen) handleClear()

		const entityIds = assotiatedEntity.map(a => a?.value);
		onClose({ name, entityIds }, isOpen, (slot && slot._id) || null);
	};

	const handleClear = () => {
		setName('');
		setAssotiatedEntity([])
	};

	return (
		<Dialog
			fullWidth={true}
			open={isOpen}
			onClose={notBackdropClicked(() => handleClose(false))}
			aria-labelledby="dialog-title"
			aria-describedby="dialog-description"
		>
			<CloseBar onClose={() => handleClose(false)} title={!slot ? t('slots.add_slot') : t('slots.edit_slot')}/>
			<DialogContent dividers style={{ overflow: 'hidden' }}>
				<TextField
					required size="small" style={{ margin: 8 }}
					label={t('slots.slot')}
					onChange={(event) =>{
						let value = event.target.value;
						value = value.replace(/[^0-9A-Za-z-_.]/gi, '');
						setName(value);
					}}
					variant="outlined"
					fullWidth
					value={name || ''}
				/>
				{assotiatedEntitiesList && isOpen && <Autocomplete
					multiple
					size="small"
					fullWidth
					style={{ margin: 8 }}
					options={assotiatedEntitiesList}
					groupBy={(option) => option.scope}
					getOptionLabel={(option) => option?.name}
					value={assotiatedEntity}
					onChange={(event, value) => { setAssotiatedEntity(value) }}
					filterSelectedOptions
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							label={t('slots.entities')}
						/>
					)}
				/>}

			</DialogContent>
			<DialogActions>
				<Button onClick={() => handleClose(false)} color="primary">
					{t('common.cancel')}
				</Button>
				{/* eslint-disable-next-line no-undef */}
				<Button onClick={() => handleClose(true)} color="primary" autoFocus>
					{t('common.save')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

class Slots extends React.Component {
	constructor(props) {
		super(props);
		const { t } = props;
		this.state = {
			//rows: null,
			showModal: false,
			modalContent: null,
			rowId: null,
			editDialogOpen: false,
			//assotiatedEntitiesList: null,
			slot: null
		};
	}

	setMessageErrorState = (obj) => {
		this.props.dispatch(setMessageState(obj));
		this.props.dispatch(setIsLoading(false));
	};

	showSnackBar = (obj) => {
		this.props.dispatch(setMessageState(obj));
	};

	getAllSlots = () => {
		const { projectId, dispatch } = this.props;
		dispatch(fetchAllSlots({ projectId }));
	}

	componentDidMount() {
		this.getAllSlots()
	}

	componentWillUnmount() {
		this.abortController.abort();
	}

	abortController = new window.AbortController();

	handleEditRow = (id, name) => {
		this.props.dispatch(setIsLoading(true));
		getData(`/api/slot/${id}`)
			.then((data) => {
				if (data.error) {
					this.setMessageErrorState({ snackBarMessages: data.error.message, snackBarVariant: MESSAGE_STATUS.ERROR, snackBarState: true });
				}
				else {
					this.setState({ slot: data.slot, editDialogOpen: true });
					this.props.dispatch(setIsLoading(false));
				}
			})
			.catch(error => {
				this.setMessageErrorState({ snackBarMessages: error.message, snackBarVariant: MESSAGE_STATUS.ERROR, snackBarState: true });
				this.props.dispatch(setIsLoading(false));
			});
	};

	handleDeleteRow = (id, name) => {
		const { t } = this.props;
		this.setState({ modalContent: `${t('slots.delete_slot')}: "${name}"?`, showModal: true, rowId: id });
	};

	handleCloseModal = (modalState) => {
		const { rowId } = this.state;
		this.setState({ showModal: false });
		if (modalState === DIALOG_USER_STATE.AGREE && rowId) {
			deleteData(`/api/slot/${rowId}`)
				.then((data) => {
					if (data.error) {
						this.setMessageErrorState({ snackBarMessages: data.error.message, snackBarVariant: MESSAGE_STATUS.ERROR, snackBarState: true });
					}
					else {
						this.getAllSlots();
					}
				})
				.catch(error => {
					this.setMessageErrorState({ snackBarMessages: error.message, snackBarVariant: MESSAGE_STATUS.ERROR, snackBarState: true });
				});
		}
	};

	handleClickNewRow = () => {
		this.setState({ editDialogOpen: true, slot: null });
	};

	handleClose = (dialogState, isOpen, rowId) => {
		if (dialogState.keyCode === 27 || !isOpen) {
			this.setState({ editDialogOpen: false });
			return;
		}

		//api/slot creates a new slot with JSON {slot: {name, scope [app], entityIds, project}}.
		//scope is enum (app, system). entityIds is array of enity names, not ids! ["app.entity1", "@sys.entity2"] project is only required if scope=app.
		//For entries scope=system, this field will be removed from the document before creation and update operations.
		//dialogState = {...dialogState, {project:this.props.projectId, }};
		if (rowId) {
			putData(`/api/slot/${rowId}`, { "slot": { ...dialogState, project: this.props.projectId } })
				.then((data) => {
					if (data.error) {
						this.showSnackBar({ snackBarMessages: data.error.message, snackBarVariant: MESSAGE_STATUS.ERROR, snackBarState: true });
					}
					else {
						this.getAllSlots();
					}
					this.setState({ editDialogOpen: false });
				})
				.catch(error => {
					this.showSnackBar({ snackBarMessages: 'Save Slot error:' + error.message, snackBarVariant: MESSAGE_STATUS.ERROR, snackBarState: true });
					this.setState({ editDialogOpen: false });
				});
		}
		else {
			postDataNew(
				`/api/slot`,
				{ "slot": { ...dialogState, project: this.props.projectId } },
				this.props.dispatch,
				data => {
					this.getAllSlots();

					this.setState({ editDialogOpen: false });
				})
			// No message on error (if needed will be added)
		}
	};

	handleClickUploadSlots = (fileType, formData) => {
		const { t } = this.props;

		this.props.dispatch(setIsLoading(true));
		uploadFile(`/api/project/${this.props.projectId}/data_schema/upload`, formData)
			.then((data) => {
				this.props.dispatch(setIsLoading(false));
				if (data.error) {
					this.props.dispatch(setMessageState({ snackBarMessages: data.error.message, snackBarVariant: MESSAGE_STATUS.ERROR, snackBarState: true }));
				}
				else {
					this.getAllSlots();
					//dispatch(updateDataModel(data.model))
				}
			})
			.catch(error => {
				this.props.dispatch(setMessageState({ snackBarMessages: `${t('train.upload_model_error')}: ${error.message}`, snackBarVariant: MESSAGE_STATUS.ERROR, snackBarState: true }));
				this.props.dispatch(setIsLoading(false));
			});

	};

	handleClickDownLoadSlots = () =>
    download(`/api/project/${this.props.projectId}/data_schema/download`, this.props.dispatch);

	render() {
		const { modalContent, showModal, editDialogOpen, slot } = this.state;
		const { t, slots, assotiatedEntitiesList } = this.props;

		const headCells = [
			{ _id: 'name',      width: "40%", label: t('slots.slot'),                    textSearch: true },
			{ _id: 'entityIds', width: "40%", label: t('slots.entities'), align: 'left',   filterOn: true },
		];

		return (
			<Fragment>
        {showModal && <ConfirmDialog
          open={showModal}
          title={t('slots.confirm_title')}
          content={modalContent}
          closeModal={(modalState) => this.handleCloseModal(modalState)}
        />}
        <EditDialog
          {...this.props}
          slot={slot}
          isOpen={editDialogOpen}
          onClose={(obj, isOpen, rowId) => this.handleClose(obj, isOpen, rowId)}
          assotiatedEntitiesList={assotiatedEntitiesList}
        />
        {slots && <EnhancedTable
					id="slots"
					headCells={headCells}
					rows={slots}
					passedPage={true}
					toolBarName={t('menu.slots')}
					newRowTitle={t("slots.add_slot")}
					handleClickNewRow={() => this.handleClickNewRow()}
					handleClickUpdateRow={this.getAllSlots}
					rowsOnPage={setDefaultRowsOnPage(slots.length)}
					handleRowItemChanged={() => this.handleRowItemChanged}
					checkBoxTableCell={(id, name, index) => (
						<TableCell padding="default">{index + 1}</TableCell>
					)}
					customHeaderButtons={() => {
						return <Fragment>
							<Upload
								btnComponent={<IconButton title={t('common.upload')} Icon={PublishIcon} component="span"/>}
								fileNameShow={false}
								size="small"
								onFileLoad={(fileType, formData) => this.handleClickUploadSlots(fileType, formData)}
							/>
              <div style={{margin: 10}}>
                {iconList([
                  ['common.download', GetAppIcon, this.handleClickDownLoadSlots],
                ], { t })}
              </div>
						</Fragment>
					}}
					customBtns={(name, id) => (
						<div style={{width: "25%", display: "flex"}}>
              {iconList([
                ['common.edit',   EditIcon,   () => this.handleEditRow(id, name)],
                ['common.delete', DeleteIcon, () => this.handleDeleteRow(id, name)],
              ], { t })}
						</div>
					)}
				/>
				}
			</Fragment>
		);
	}
}

const mapStateToProps = state => ({
	projectId: state.settings.projectInfo.projectId,
	slots: state.settings.slots,
	assotiatedEntitiesList: state.settings.assotiatedEntitiesList
});

export default withRouter(connect(mapStateToProps)(withTranslation()(Slots)));
