import React, { Fragment } from "react";
import { BTN } from "../../../core/constants";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { Controlled as CodeMirror } from "react-codemirror2";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseBar from "../../components//dialogCloseBar";

export function EditConfig(props) {
  const { isOpen, onClose, nluConfigText } = props;

  const { t } = useTranslation();

  const doCancel = () => {
    onClose(BTN.APPLY, { nluConfigText: nluConfigText });
  };

  return (
    <Fragment>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={isOpen}
        aria-labelledby="edit-config-dialog-title"
      >
        <CloseBar onClose={doCancel} title={t("train.data_schema")}/>
        <DialogContent style={{ overflow: "hidden", padding: 0 }}>
          <CodeMirror
            value={nluConfigText}
            options={{
              mode: "yaml",
              lineNumbers: true,
            }}
            readOnly
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
