import React, { Fragment, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { setIsLoading, fetchTestModelList } from '../../../features/settings';
import { fetchIntents, fetchSlots } from "../../../features/annotationSettings";
import { setMessageState } from '../../../features/messageInfo';
import { deleteFilter } from "../../../features/filters";
import { getDataNew, postDataNew, deleteDataNew, putDataNew } from '../../../core/fetchService'
import { MESSAGE_STATUS, DIALOG_USER_STATE } from '../../../core/constants';
import EnhancedTable, { viewLink } from '../../components/projectTable';
import MuiIconButton from '@material-ui/core/IconButton';
import UpdateIcon from "@material-ui/icons/Update";
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ConfirmDialog from '../../components//confirmDialog'
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from "@material-ui/icons/Block";
import BrightnessHighIcon from "@material-ui/icons/BrightnessHigh";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { setDefaultRowsOnPage, wrapTranslation, GlobalState, getRunConfigOption, CHK_FAIL } from '../../../core/utils';
import ReportDialog, { DIFF_VIEW_ID, getTestModelIntentAcc, getTestModelSlotAcc, getTestModelWRR } from './ReportDialog'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { withTranslation, useTranslation } from 'react-i18next';
import { withStyles } from "@material-ui/styles";
import CloseBar from "../../components/dialogCloseBar";
import {
  Table, TableBody, TableRow, TableCell,
} from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';

import IconButton from "../../components/material-ui/IconButton";

import {
  viewStatus, linkStyle, Runnable,
}                                           from "../../components/taskStatus";

import notBackdropClicked from "../../components/helpers/notBackdropClicked";

const viewTestStatus = (row, cell) => {
  return viewStatus(row, cell, {
    s_obj: row,
    percent: row.progress?.percent,
    in_progress: 'Test',
  });
};

// const useModal = () => {
//   const [isShowing, setIsShowing] = useState(false);

//   function toggle() {
//     setIsShowing(!isShowing);
//   }

//   return {
//     isShowing,
//     toggle,
//   }
// };

const styles = (theme) => ({
  dialogField: {
    margin: 8
  }
});

function EditDialog(props) {
  const { isOpen, onClose, testModelList, dataset, model, name, description, classes } = props;
  const [modelData, setModelData] = useState({});
  const scroll = 'paper';
  const { t } = useTranslation();

  useEffect(() => {
    setModelData({dataset, model, name, description})
  }, [dataset, model, name, description])

  const onCancel = () => onClose();

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={isOpen}
      onClose={notBackdropClicked(onCancel)}
      scroll={scroll}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <CloseBar onClose={onCancel} title={dataset || model ? t('tests.edit_test') : t('tests.add_test')}/>
      <DialogContent dividers={scroll === 'paper'} style={{overflow: 'hidden'}}>
        <TextField
          id="testName"
          required
          size="small"
          className={classes.dialogField}
          label={t('common.name')}
          onChange={(event, value) => setModelData({ ...modelData, name: event.target.value })}
          variant="outlined"
          fullWidth
          defaultValue={name || null}
        />
        <TextField
          id="testDescription"
          size="small"
          className={classes.dialogField}
          label={t('common.description')}
          onChange={(event, value) => setModelData({ ...modelData, description: event.target.value })}
          variant="outlined"
          multiline
          rows={2}
          fullWidth
          defaultValue={description || null}
        />
        <Autocomplete
          required
          id="testSet"
          size="small"
          fullWidth
          options={(testModelList && testModelList.datasets) || []}
          onChange={(event, value) => setModelData({ ...modelData, dataset: value?._id || null })}
          getOptionLabel={(option) => option.name}
          defaultValue={(dataset && testModelList.datasets.find(d => d._id === dataset)) || null}
          renderInput={(params) => (
            <TextField {...params} className={classes.dialogField} variant="outlined" label={t('tests.test_set')} />
          )}
        />
        <Autocomplete
          required
          id="model"
          fullWidth
          size="small"
          ListboxProps={{ style: { maxHeight: 120 } }}
          options={(testModelList && testModelList.models) || []}
          onChange={(event, value) => setModelData({ ...modelData, model: value?._id || null })}
          getOptionLabel={(option) => option.name}
          defaultValue={(model && testModelList.models.find(d => d._id === model)) || null}
          renderInput={(params) => (
            <TextField {...params} className={classes.dialogField} variant="outlined" label={t('tests.model')} />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" autoFocus>
          {t('common.cancel')}
        </Button>
        <Button disabled={!modelData.name || !modelData.model || !modelData.dataset} onClick={() => onClose(modelData)} color="primary">
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

class Tests extends React.Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.state = {
      showModal: null,
      dialogTitle: null,
      modalContent: null,
      btnNameAgree: null,
      editDialogOpen: false,
      showJsonModal: false,
      showEntityTab: true,
      rowId: null,
      modelCloneName: '',
      showConfirmStatus:false,
      modalContentStatus:null
    };

    this.runnable = new Runnable({
      getStatus: tm => tm?.status,
      statusName: 'Test',
      updateStatus: this.getTestModelList,
    });
    this.globalState = new GlobalState(this.updateGlobalState);
    this.eventSource = new EventSource(`/events`);
  }

  setMessageErrorState = (obj) => {
    this.props.dispatch(setMessageState(obj));
    this.props.dispatch(setIsLoading(false));
  };

  showSnackBar = (obj) => {
    this.props.dispatch(setMessageState(obj));
  };

  getTestModelList = () => {
    this.props.dispatch(fetchTestModelList(this.props));
  };

  fetchIntents = () => {
    this.props.dispatch(fetchIntents(this.props));
  };

  fetchSlots = () => {
    this.props.dispatch(fetchSlots(this.props));
  };

  updateGlobalState = () => {
    this.fetchIntents();
    this.fetchSlots();
    this.getTestModelList();
    this.props.dispatch(deleteFilter(DIFF_VIEW_ID));
  };

  componentDidMount() {
    this.globalState.componentDidMount();

    this.eventSource.addEventListener('message', event => {
      this.getTestModelList();
    });
  }

  componentDidUpdate(prevProps) {
    this.runnable.componentDidUpdate(this.props.testModelList?.testmodels);
    this.globalState.componentDidUpdate(prevProps, this.props);
  }

  componentWillUnmount() {
    this.runnable.componentWillUnmount();

    if(this.eventSource){
      this.eventSource.close();
    }
    this.abortController.abort();
  }

  abortController = new window.AbortController();

  handleEditRow = (id, name) => () => {
    this.setState({
      editDialogOpen: true,
      rowId: id
    });
  };

  handleCloneRow = (id, name) => () => {
    const { t } = this.props;
    this.setState({
      rowId: id,
      modelCloneName: name,
      showModal: 'clone',
      dialogTitle: t('tests.clone'),
      modalContent: (
        <TextField
          defaultValue={name}
          onChange={(event, value) => { this.setState({ modelCloneName: event.target.value }) }}
          autoFocus
          size="small" style={{ margin: 8, paddingRight: 17 }}
          label={t('common.name')}
          variant="outlined"
          fullWidth
        />
      ),
      btnNameAgree: t('common.clone'),
    });
  };

  handleDeleteRow = (id, name) => () => {
    const { t } = this.props;
    this.setState({
      rowId: id,
      showModal: 'delete',
      dialogTitle: t('tests.delete'),
      modalContent: `${t('tests.delete_test_model')}: "${name}"?`,
      btnNameAgree: null,
    });
  };

  handleClickNewRow = () => {
    this.setState({ editDialogOpen: true, rowId: null });
  };

  handleCloseEditDialog = obj => {
    this.setState({ editDialogOpen: false });
    if (!obj)
      return;

    const { dispatch, projectId, t } = this.props;
    const { rowId } = this.state;

    (rowId
      ? putDataNew.bind(null, `/api/testmodel/${rowId}`, { testmodel: obj })
      : postDataNew.bind(null, `/api/testmodel`, { testmodel: { ...obj, project: projectId } })
    )(
      dispatch,
      data => {
        dispatch(setMessageState({ snackBarMessages: t('tests.test_saved'), snackBarVariant: MESSAGE_STATUS.SUCCESS, snackBarState: true }));
        this.getTestModelList();
      })
  };

  doAction = (id, action) =>
    getDataNew(`/api/testmodel/${id}/${action}`, this.props.dispatch, data => {
      // make blue and add time 20 sec
      data.message && this.props.dispatch(setMessageState({
        snackBarMessages: data.message,
        snackBarVariant: MESSAGE_STATUS.INFO,
        snackBarState: true,
        snackBarDuration: 15*1000,
      }));
      this.getTestModelList();
    });

  stopTest = (id) => {
    const { dispatch, t } = this.props;

    getDataNew(`/api/testmodel/${id}/stop`, dispatch, data => {
      dispatch(setMessageState({ snackBarMessages: t('tests.test_stopped'), snackBarVariant: MESSAGE_STATUS.SUCCESS, snackBarState: true }));
      this.getTestModelList();
    });
  };

  handleCloseModal = modalState => {
    const { showModal: action, modelCloneName, rowId } = this.state;
    const { dispatch, testModelList } = this.props;
    this.setState({ showModal: null });
    if (modalState != DIALOG_USER_STATE.AGREE)
      return;
    if (action == 'clone') {
      const obj = { ...testModelList.testmodels.find(t => t._id === rowId) };
      if (obj) {
        ['createdAt', 'status', 'updatedAt', '_id', 'data', 'result', 'labels'].forEach(attr => {
          delete obj[attr];
        });
        this.setState({ rowId: null }, () => {
          this.handleCloseEditDialog({ ...obj, name: modelCloneName });
        });
      }
    } else if (action == 'delete') {
      deleteDataNew(`/api/testmodel/${rowId}`, dispatch, data => {
        this.getTestModelList();
      });
    } else {
      CHK_FAIL(`unknown model action: ${action}`);
    }
  };

  viewIntentAcc = (t) => (row, cell) => {
    const View = () => {
      const [clicked, setClicked] = useState(false);      
      useEffect(() => { !row.outdated && setClicked(false) }, [row.outdated]);
      return (
        <>
          {viewLink(row, cell)}
          {row?.result.length && row.outdated ? (
            <Tooltip title={t("common.refresh")}>
              <MuiIconButton
                size="small"
                style={{marginLeft: 10, marginTop: -3}}
                onClick={() => { setClicked(true); this.testSetChanged(row, () => setClicked(false)) }}
              >
                {clicked ? <CircularProgress size="21px"/> : <UpdateIcon/>}
              </MuiIconButton>
            </Tooltip>
          ) : null}
        </>
      );
    };
    return <View/>
  };

  testSetChanged = (row, onError) => {
    postDataNew(`/api/testmodel/${row._id}/testset_changed`, {}, this.props.dispatch, data => {
      this.getTestModelList();
    }, {
      disable_show_loading: true,
    })
      .then(data => data.error && onError && onError(data.error));
  };

  render() {
    const {
      showConfirmStatus, modalContentStatus,
      editDialogOpen, rowId, showJsonModal, showEntityTab,
      showModal, dialogTitle, modalContent, btnNameAgree,
    } = this.state;
    const { testModelList, classes, dispatch, intentsList, slotsList } = this.props;
    const { t } = wrapTranslation(this.props.t);
    const testmodel = (testModelList?.testmodels || []).find(tm => tm._id == rowId);
    const testIntentAccStyle = tm => tm.outdated ? {color: 'lightgrey'} : {};
    const is_asr = tm => (testModelList?.models || []).find(m => m._id == tm.model)?.type == 'ASR_E2E';
    const has = type => (testModelList?.testmodels || []).some(tm => type == 'asr' ? is_asr(tm) : tm.data?.[type]);
    const all_asr = type => (testModelList?.testmodels || []).every(is_asr);
    const showReport = (showEntityTab = {}) => row => {
      if (row._id != rowId)
        dispatch(deleteFilter(DIFF_VIEW_ID));
      this.setState({ showJsonModal: true, ...showEntityTab, rowId: row._id });
    };

    const headCells = [
        { _id: 'name',        width: "10%",   label: t('common.name'),    textSearch: true },
        { _id: 'datasetname', width: "10%",   label: t('tests.test_set'), filterOn: true   },
        { _id: 'model',       width: "10%",   label: t('tests.model'),    filterOn: true   },
        { _id: 'date',        width: "10%",   label: t('tests.date'),     dateTime: true   },
        {
          _id: 'status',      width: "10%",   label: t('tests.status'),   link: row => {
            const modalContentStatus = (
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell style={{width: '200px'}}>{t('tests.test_status')}</TableCell>
                    <TableCell>{row.error || ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('tests.test_started')}</TableCell>
                    <TableCell><span style={{textDecoration:'underline'}}>{row.startTestAt}</span></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>{t('tests.test_finished')}</TableCell>
                    <TableCell><span style={{textDecoration:'underline'}}>{row.finishTestAt}</span></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            );
            this.setState({ showConfirmStatus: true, modalContentStatus: modalContentStatus });
          },
          style: linkStyle(row => [row]),
        },
        ...(has('int') && !all_asr() ? [
        {
          _id: 'intent',      width: "10%",   label: t('tests.intents_acc'),  link: showReport({ showEntityTab: false }),

          align: 'left',
          style: testIntentAccStyle,
        }] : []),
        ...(has('ent') ? [
        {
          _id: 'entity',      width: "10%",   label: t('tests.entities_f1'),  link: showReport({ showEntityTab: true }),
        }] : []),
        ...(has('asr') ? [
        {
          _id: 'asr',         width: "15%",   label: t('tests.asr_WRR'),      link: showReport(),
        }] : []),
      ];

    return (
      <Fragment>
        {showModal &&
          <ConfirmDialog
            title={dialogTitle}
            content={modalContent}
            closeModal={this.handleCloseModal}
            btnNameAgree={btnNameAgree}
          />}
        {showConfirmStatus &&
          <ConfirmDialog
            open={true}
            content={modalContentStatus}
            showBtnNameAgree={false}
            title={t('common.info')}
            btnNameDisagree={t('common.close')}
            closeModal={() => this.setState({showConfirmStatus:false})}
          />}
        <EditDialog
          testModelList={testModelList}
          name={testmodel?.name}
          description={testmodel?.description}
          dataset={testmodel?.dataset}
          model={testmodel?.model}
          isOpen={editDialogOpen}
          classes={classes}
          onClose={this.handleCloseEditDialog}
        />
        {showJsonModal &&
          <ReportDialog {...{
            testModelId: rowId,
            showEntityTab,
            testModelList,
            intentsList,
            slotsList,
            onClose: () => { this.setState({ showJsonModal: false }) },
            testSetChanged: this.testSetChanged.bind(this),
          }} />}
        {testModelList && Object.keys(testModelList).length > 0 &&
          <EnhancedTable
            id="tests"
            headCells={headCells}
            passedPage={true}
            rows={testModelList.testmodels.map(r => ({
              ...r,
              datasetname: testModelList.datasets.find(d => d._id === r.dataset)?.name,
              model: testModelList.models.find(d => d._id === r.model)?.name,
              date: r.status == 'Ready' && r.finishTestAt ? r.finishTestAt : r.updatedAt,
              intent: getTestModelIntentAcc(r),
              entity: getTestModelSlotAcc(r),
              asr: getTestModelWRR(r),
              finishTestAt: r.finishTestAt && new Date(r.finishTestAt).toLocaleString(),
              startTestAt: r.startTestAt && new Date(r.startTestAt).toLocaleString(),
              error: r.error?.json?.message || r.error?.error || r?.error
            }))}
            viewCell={{ status: viewTestStatus, intent: this.viewIntentAcc(t) }}
            toolBarName={t('tests.test_results')}
            newRowTitle={t("tests.add_test")}
            handleClickNewRow={this.handleClickNewRow}
            handleClickUpdateRow={this.getTestModelList}
            rowsOnPage={setDefaultRowsOnPage(testModelList.length)}
            checkBoxTableCell={(id, name, index) => (
              <TableCell padding="default">{index + 1}</TableCell>
            )}
            customBtns={(name, id) => {
              const isActive = this.runnable.isActive(testModelList?.testmodels, id);
              const ICONS = [
                ['edit',      this.handleEditRow,       EditIcon],
                ['clone',     this.handleCloneRow,      FileCopyIcon],
                ['delete',    this.handleDeleteRow,     DeleteIcon],
              ];
              return (
                <div style={{width: "25%", display: "flex"}}>
                  {!getRunConfigOption('trainTestIcons') ?
                    <Button variant="outlined" size="small"
                      onClick={() => this.doAction(id, !isActive ? 'test' : 'stop')}
                    >
                      {t(!isActive ? 'tests.test' : 'common.stop')}
                    </Button>
                    :
                    <IconButton
                      onClick={() => this.doAction(id, !isActive ? 'test' : 'stop')}
                      title={t(!isActive ? 'tests.test' : 'common.stop')}
                      Icon={isActive ? BlockIcon : BrightnessHighIcon}
                    />
                  }
                  {ICONS.map(i => <IconButton key={i[0]} title={t("common."+ i[0])} onClick={i[1](id, name)} Icon={i[2]}/>)}
                </div>
              )}}
          />
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  projectId:      state.settings.projectInfo.projectId,
  testModelList:  state.settings.testModelList,
  intentsList:    state.annotationSettings.intentsList,
  slotsList:      state.annotationSettings.slotsList,
});

export default withRouter(connect(mapStateToProps)(withStyles(styles)(withTranslation()(Tests))));
